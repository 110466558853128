/* Font Setup */
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
html {
  overscroll-behavior: none;
}
body {
  font-family: 'DM Sans';
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  touch-action: pan-x pan-y;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

footer {
  padding-top: 0em;
}

@media (min-width: 1200px) {
  footer .footer_left {
    text-align: left !important;
  }

  footer {
    padding-top: 4em;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}


.cursor-disabled {
  cursor: not-allowed !important;
}


.newloginmodal .btn-close {
  float: right;
}

.newloginmodal input {
  padding: 10px 20px;
  border-radius: 25px;
  border: solid 2px #AEAEAE;
  margin: 15px 0;
}

.newloginmodal input::placeholder {
  font-style: italic;
  font-size: 14px;
}

.newloginmodal .loginbtn {
  border-radius: 25px;
  padding: 13px 20px;
  margin-bottom: 15px;
}

.newloginmodal .forgotpasstxt {
  text-align: center;
  margin: 20px 0px 20px 0px;
  font-weight: normal;
}

.newloginmodal .eller {
  font-weight: bold;
  font-size: 20px;
}

.newloginmodal input:focus {
  outline: none;
  border-color: #AEAEAE;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.newloginmodal .fbbtn,
.newloginmodal .fbbtn:focus,
.newloginmodal .fbbtn:visited,
.newloginmodal .fbbtn:active,
.newloginmodal .fbbtn:hover {
  background-color: #3C5A9A !important;
  color: #fff !important;
  padding: 14px 25px;
  border-radius: 10px;
  text-align: center;
}

.newloginmodal .fbicon {
  position: absolute;
  margin-left: -27px;
  margin-top: -1px;
}

.newloginmodal .googlebtn,
.newloginmodal .googlebtn:focus,
.newloginmodal .googlebtn:visited,
.newloginmodal .googlebtn:active,
.newloginmodal .googlebtn:hover {
  color: #000 !important;
  padding: 14px 25px;
  border-radius: 25px;
  text-align: center;
}


.newloginmodal .emailbtn,
.newloginmodal .emailbtn:focus,
.newloginmodal .emailbtn:visited,
.newloginmodal .emailbtn:active,
.newloginmodal .emailbtn:hover {
  color: #000 !important;
  padding: 14px 25px;
  border-radius: 25px;
  text-align: center;
}

.newloginmodal .guestbtn,
.newloginmodal .guestbtn:focus,
.newloginmodal .guestbtn:visited,
.newloginmodal .guestbtn:active,
.newloginmodal .guestbtn:hover {
  background-color: #4b4b4b !important;
  color: #fff !important;
  padding: 14px 25px;
  border-radius: 25px;
  text-align: center;
}

.signupmodal .modal-content {
  /* max-width: 600px; */
}

.agree_to_terms {
  margin-top: 21px;
  margin-left: 10px;
}

._checkmark {
  border: 0px;
  width: 28px;
  height: 29px;
}

.fielderror {
  margin: 0;
  margin-top: -11px;
  font-size: 12px;
  font-style: italic;
  color: red;
  margin-left: 10px;
}

.flex {
  display: flex;
}

.color-primary {
  color: #94c11e !important
}

.form-check-input[type=radio]:checked:after {
  background-color: #95c11f;
}

.loaderstyle{
  z-index: 9999;
  color: #95c11f;
  position: absolute;
  margin-top: 40vh;
  left: 50%;
}