.my-facebook-button-class{
    border-radius: 10rem;
    background-color: #fbfbfb;
    vertical-align: bottom;
    border: 0;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 10%);
    font-weight: 500;
    padding: 0.625rem 1.5rem 0.5rem 1.5rem;
    font-size: .75rem;
    line-height: 1.5;
    width: 100%;
    margin-bottom: 2em;
  }
  
  @media (min-width: 769px) {  
    .login_container{
      max-width: 500px;
    }
  }
  