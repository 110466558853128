/* @include media-breakpoint-up(md) { ... }
@include media-breakpoint-up(lg) { ... }
@include media-breakpoint-up(xl) { ... }
@include media-breakpoint-up(xxl) { ... } */
/* textarea:focus{
  outline:solid 10px red !important;
} */

/* Global */
button,
button:hover,
button:active,
button:focus {
  box-shadow: none !important;
}

.transparent-bg {
  background-color: transparent !important;
}

/* Modals */
.openhours_modal {}

.openhours_modal h4 {
  font-family: Ubuntu;
  font-weight: 700;
  font-size: 2rem;
  color: #000;
  padding: 0rem 0rem 1.5rem 0rem;
  ;
}


.openhours_modal p {
  font-size: 1rem;
  line-height: 1.25;
  color: #000;
}


.toggle_holder {
  display: inline-block;
  background-color: #D9D9D9;
  float: right;
  border-radius: 30px;
  padding: 0px 25px 0px 25px;
}

.processing_watch svg {
  margin: auto;
}

.overflow_none {
  overflow-y: visible !important;
}

.extraCountersButtonNew {
  font-size: 18px;
}

.navbar-nav.w-100 {
  width: auto !important
}

.processing_watch {
  padding: 1.5rem;
  max-width: 200px;
  margin: auto;
}

.validation_error {
  color: red;
  font-size: 15px;
  font-weight: 700;
}

.complete_processing_amount_col {
  text-align: right;
}

.confirm_order_div {
  margin: auto;
}

.breakdown_main2 {
  padding-left: 1.5rem;
}

.extraCountersInputNewCol {
  margin-left: 4px;
  display: inline-block;
  margin-right: 4px;
}

.extraCountersButtonNew,
.extraCountersButtonNew:hover,
.extraCountersButtonNew:active {
  color: #94c11e;
  margin: 0;
  padding: 0;
}

.extraCountersNew {
  background-color: #D9D9D9;
  float: right;
  border-radius: 30px;
  padding: 5px 7px 1px 7px !important;
  opacity: 1;
  display: inline-flex;
  align-items: center;
}

.extraCountersNew .form-control {
  margin: 0px 0px 0px 0px !important;
}

.extraCountersInputNew {
  text-align: center;
}

.extraCountersButtonNewColMinus {
  text-align: right;
  display: inline;
  font-size: 1em !important;

}

.extraCountersButtonNewColPlus {
  text-align: left;
  display: inline;
  font-size: 1em !important;

  ;
}

.extraCountersButtonNew {
  border: none;
}

.breakdown_main3 {
  padding-left: 3rem;
}

.mobile_modals,
.mobile_modals:hover {
  /* background-color: #95C11F !important;
    border-color: #95C11F !important;
    color: white; */
}

.dinein_takeout_mobile_only {
  display: none;
}

.react-tel-input .flag-dropdown { 
  background-color: transparent;
  border-right: solid 1px #aaa;
}

.react-tel-input .flag-dropdown:hover{
  background-color: transparent;
}

.mycontact .react-tel-input .form-control {
  width: 100%;
  border: none;
  background-color: transparent;
  border: solid 1px #aaa;
}

.mycontact .react-tel-input .form-control:focus {
  outline: none;
  box-shadow: none;
}

.react-tel-input .selected-flag {
  border: none !important;
  padding: 0px 0px 0px 20px;
  width: 55px;
}
.react-tel-input .flag-dropdown.open .selected-flag,
.react-tel-input .flag-dropdown.open .selected-flag:hover
{
  background: transparent;
}

.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus{
  background: transparent;
}

.form-check-input[type=checkbox]:checked,
.form-check-input:checked,
.form-check-input[type=radio],
.form-check-input[type=radio]:checked:after,
.form-check-input[type=radio]:checked {
  /* background-color: #95C11F !important; */
  border-color: #95C11F !important;
  margin-top: 8px;
  top: 0;
}

.category_intro {
  text-align: left;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.category_intro .card-title {
  font-size: 25px;
  font-weight: 700;
  color: #3a3a3a;
  /*text-transform: uppercase; */
  /* font-size: xxx-large; */
}

.card-title h5 {
  font-size: 20px;
  color: #3a3a3a;
  font-weight: 700;
}

.modal-dialog {
  max-width: 680px;
}

.modal-content {
  width: 680px;
  border-radius: 1.875rem;
}

.extraCountersButton {
  /* margin-top: -10px; */
}

.no_border {
  border: none;
}

.no_padding_top {
  padding-top: 0;
}

.no_box_shadow {
  box-shadow: none;
  border: none;
}

.navbar-brand:hover {
  background: rgb(250, 250, 250);
  border: none;
}

.detail_modal_counter .form-outline input {
  font-size: 15px;
  padding: 0;
  border: 0;
  padding-top: 0;
}

/* MENU LIST DESKTOP */
.menu_list_height {
  /* height:295px; */
  padding-top: 20px;
  padding-bottom: 40px;
}

.desktop_image_wrap {
  width: auto;
}

.detail_tab_content_wrapper {
  padding: 0 !important;
}

.detail_tab_content_wrapper .card-body {
  padding: 0 !important;
}

.detail_tab_content_descr {
  /* padding-top:20px; */
  padding-bottom: 0;
  text-align: left;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0px;
  /*color: #96a1b7;
     opacity: 0.5; */
}

.dinein_selected {
  margin-bottom: 35px;
}

.detail_tab_content_mobile {
  padding-bottom: 0;
  margin-bottom: 0;
}

.detailTabCartVariantItems {
  --mdb-gutter-x: 0;
  --mdb-gutter-y: 0;
}

.detailTabCartExtra,
.detailTabCartVariantItems {
  margin: 0px 0px 10px 0px;
}

.variation_spacer {
  padding: 0px 30px 0px 30px;
}

.detailTabCartVariation {
  --mdb-gutter-x: 0;
  --mdb-gutter-y: 0;
}

.detailTabCartVariation~.detailTabCartVariation {
  --mdb-gutter-x: 0;
  --mdb-gutter-y: 0;
}

.variation_options:last-of-type {}

.attribLabel {
  --mdb-gutter-x: 0;
  --mdb-gutter-y: 0;
}

.detailTabCartVariantOptions {}

.detailTabCartVariantOptions .form-check,
.variationxoption .form-check {
  padding-left: 0;
}

.detailTabCartVariantOptions {
  width: 100%;
  margin: 10px 0px 0px 0px;
  align-items: center;
}

.variationxoption {
  padding-left: 1.5rem;
}

.variationxoption2 {
  text-align: left;
  font-size: 12px;
  font-weight: 700;
  color: #000;
}

.variationxoption2 h5 {
  text-align: right;
  font-size: 12px;
  font-weight: 700;
  line-height: 38px;
  color: #000;
}

.detailTabCartExtraItemsCol {
  /* padding-right: 0 !important;
  padding-left: 0 !important; */
  padding: 5px
}

.detailTabCartExtraItemsCol .card-body {
  padding: 0px;
}

.detailTabCartExtraItemsBodyText {
  color: #000;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 35px;
  opacity: 1;
  text-align: left;
}

.detail_tab_menu_item_name .detail_tab_content_descr {
  /* font-size:36px; */
}

.anchor_custom,
.menu_list_height>a {
  /* position:absolute; */
}

.menu_title {
  font-size: 1.2em !important;
  color: #3a3a3a;
}

.detail_tab_content2 {
  font-size: 20px;
  position: relative;
  /* bottom: -35px; */
}

.mid_btn_tab a.nav-link {
  text-align: left;
  font-size: 22px;
  letter-spacing: 0px;
  color: #95C11F;
  opacity: 1;
}

.detail_tab_menu_item_name {
  /* font-size: 36px !important; */
}

.detail_modal_counter .form-outline .form-notch {
  display: none;
}

.our_menu {
  height: 75px;
  text-align: left;
  /* font: normal normal normal 36px/90px DM Sans; */
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 0px;
  color: #3A3A3A;
  opacity: 1;
  margin-bottom: 0;
}

.add_to_cart_col {
  display: inline-flex;
  margin-left: 1em;
  margin-right: 1em;
}

.add_to_cart_col input {
  width: 3em;
  text-align: center;
}

.flag-dropdown {
  border-radius: 30px 0 0 30px !important;
  height: 50px;
}

.mycontact input,
.css-1s2u09g-control {
  border-radius: 30px !important;
  background-color: #F5F5F5;
}

.css-1s2u09g-control,
.css-2613qy-menu,
.css-1pahdxg-control {
  width: calc(100%-1.5rem);
}

.dinein_btn_grp1 i,
.dinein_btn_grp2 i,
.dinein_btn_grp3 i,
.dinein_btn_grp4 i {
  font-size: 18px;
}

.datetime_select {
  font-size: 15px;
  font-weight: bold;
}

.order_in_advance div:nth-child(1),
.order_now_btn div:nth-child(1) {
  text-align: left;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 15px;
  text-transform: none;
}

.order_in_advance i {
  color: white;
}

.order_now_btn,
.order_in_advance {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #222222;
  border-radius: 15px;
  opacity: 0.5;
}

.order_now_btn_checked,
.order_in_advance_checked {
  color: #3A3A3A;
  opacity: 1;
}

.confirm_order_title {
  /* UI Properties */
  text-align: left;
  font-size: 20px;
  font-weight: 700;
  color: #3A3A3A;
}

.input-group {
  border-radius: 8px;
}

.confirm_order_title1 {
  /* height: 92px; */
  /* UI Properties */
  text-align: left;
  color: #4f4f4f;
  font-size: 15px;
  letter-spacing: 0px;
}

.pickuptime {
  max-width: 160px;
  border: 0;
  /* border-bottom: 1px solid black; */
  font-size: 14px;
  letter-spacing: 0px;
  color: #3A3A3A;
  /* border-bottom: 1px solid black; */
  background-color: rgba(0, 0, 0, 0);
}

.confirm_order_title2 {
  height: 39px;
  /* UI Properties */
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #95C11F;
  opacity: 1;
  font-weight: bold;
}

.detail_modal_counter {
  background: #94c11e 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
  width: fit-content;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0;
}

.modal_card_counter {
  box-shadow: 0;
  box-shadow: none;
  float: right;
  --mdb-gutter-x: 0;
  --mdb-gutter-y: 0;
}

.remove_cart_row_custom {
  margin-top: 35px;
}

.hr_rule_row {
  margin-left: 4%;
  padding: 1em 0 0 0;
}

.hr_rule {
  /* margin: 30px auto 30px 2.5rem !important; */
  padding: 0px;
  position: relative;
  width: 92%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}

body {
  font-family: 'DM Sans';
}

/* START */
.details_row {
  padding-top: 100px;
  padding-bottom: 0px;
}

.restaurant_cover1_col {
  position: relative;

}

.restaurant_cover1 {
  height: 440px;
  width: 558px;
  object-fit: cover;
  margin-left: 182px;
}

.restaurant_details_holder {
  padding: 0px 50px 0px 0px;
}

.restaurant_name {}

.restaurant_name1 {
  /* width: 565px; */
  text-align: left;
  font-size: 25px;
  font-weight: 700;
  color: #3A3A3A;
  opacity: 1;
  /* height: 82px; */
}

.restaurant_description {
  font-size: 1em;
  line-height: 1.3em;
  padding: 1em 0;
}


.restaurant_address_desktop {
  padding: 0 0 1em 0;
}

.openinghours_header {
  font-weight: 700;
}

.icons {
  color: black !important;
  width: 20px;
}

.daily_schedule {
  float: right;
  font-size: 21px;
  padding: 10px 0;
}

.schedule {
  padding-bottom: 0;
  margin-bottom: 0;
}

.weekname {
  width: 80px;
  display: inline-block;
}

.adress_header {
  font-weight: 700;
}

.restaurant_address {
  height: 28px;
  /* UI Properties */
  text-align: left;
  font-size: 15px;
  letter-spacing: 0px;
  color: #3A3A3A;
}

.restaurant_name>.card-body>p {
  /* height: 28px; */
  /* UI Properties */
  text-align: left;
  font-size: 15px;
  letter-spacing: 0px;
  color: #3A3A3A;
}

.dinein_btn_grp {
  border-radius: 30px !important;
  height: 50px;
  min-width: 325px;
  max-width: 325px;
  /* opacity: 0.5; */
}

.datetime_select_p {
  margin: 20px 0px 10px 0px;
  padding: 15px 0px 10px 0px;
  font-size: 15px;
  font-weight: 700;
  border-top: 1px solid #F5F5F5;
  text-align: start;
}

.react-datepicker-wrapper {
  /* padding-top:20px; */
  /* padding-bottom:60px; */
}

.pickupdate_p {
  width: 48%;
  padding: 10px 20px 10px 20px;
  display: inline-block;
  background-color: #F5F5F5;
  border-radius: 20px;
}

.pickuptime_p {
  width: 48%;
  padding: 10px 20px 10px 20px;
  display: inline-block;
  background-color: #F5F5F5;
  border-radius: 20px;
}

.pickupdatetime_p {
  width: 4%;
  display: inline-block;
}

.pickupdatetimearrowdown1 {
  /* position:absolute;
    right:50%; */
  float: right;
  /* margin-right:5%; */
}

.pickupdatetimearrowdown2 {
  /* position:absolute;
    right:0; */
  float: right;
  /* margin-right:5%; */
}

.datetime_select {
  text-align: left;
  font: normal normal medium 16px/21px DM Sans;
  letter-spacing: 0px;
  color: #3A3A3A;
  opacity: 1;
  /* margin-top: 1em; */
  display: block;
}

.sidebar_price {
  color: #9BC22C;
  font-weight: bold;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child) {
  margin: 0;
}

.dinein_btn_grp1 {
  border-radius: 30px !important;
  background: #4b4b4b !important;
  opacity: 1 !important;
  color: FFFFFF !important;
  font-weight: 700;
  text-transform: none;
  font-size: 15px;
}

.variationx_name {
  /* padding-top: 20px; */
  color: #3a3a3a;
  font-weight: 700;
  font-size: 18px;
}

.payment_headerx>h5 {
  font-size: 24px;
  font-size: 1.2em;
  font-weight: 900;
  color: #000;
}

.payment_header {
  padding-top: 40px;
  padding-bottom: 40px;
  /* padding-bottom:40px; */
  font-size: 24px;
  letter-spacing: 0px;
  color: #3A3A3A;
  opacity: 1;
}

.select_pay_method>h5.card-title {
  font-family: 'Ubuntu', sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
  color: #000000;
}

.tips {
  margin-bottom: 20px;
}

.tips>.card-body {
  margin-top: 0;
  padding-top: 0;
  /* padding-bottom:30px; */
}

.tips>.card-body>.col {
  text-align: center;
}

.tip_choices {
  box-shadow: none;
  display: flex !important;
  overflow-x: scroll;
  padding: 1.5rem 0;
}

.tip_choices span {
  padding: 5px !important;
  box-shadow: none;
  /* min-width: 100px; */
  display: inline-block;
  box-sizing: unset;
}

.tip_choices>span>label {
  display: inline-flex;
  align-items: center;
  height: 50px;
}

.select_pay_method {
  padding-bottom: 50px;
}

.tip_choices>span>label {
  text-align: left;
  font: 20px;
  letter-spacing: 0px;
  color: #3A3A3A !important;
  opacity: 1;
  background-color: white !important;
  border: 1px solid #70707080;
  border-radius: 30px;
  opacity: 1;
  box-shadow: none !important;
  font-weight: bold;
}

.placeorder3 {
  border-radius: 15px;
  text-decoration: none;
  text-transform: yes;
}

.tipsbody {
  padding-top: 0;
  padding-bottom: 0;
}

.paymentbreakdown {
  padding-top: 0;
  padding-bottom: 0;
}

.tip_choices>span>label:hover,
.tip_choices>span>input:checked+.btn-light,
.btn-check:active+.btn-light,
.btn-light:active {
  color: black !important;
  background-color: #95C11F !important;
  box-shadow: none !important;
  font-weight: bold;
}

.tips>.card-body>.card-title {
  font-family: 'Ubuntu', sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
  color: #000000;
}

/* .tip_choices>span:checked+label,.tip_choices>span:checked+.btn-light,.tip_choices>span>label:hover,.tip_choices>span>label:checked+.btn-light, .tip_choices>span>label:active+.btn-light, .tip_choices>span>label:active, .tip_choices>span>label.active, .dropdown-toggle {
    color: black !important;
    background-color: #95C11F !important;
    box-shadow: none !important;
  }
  .tip_choices>span:checked+.btn-light, .tip_choices>span:active+.btn-light, .btn-light:active, .btn-light.active, .show>.btn-light.dropdown-toggle {
    color: black !important;
    background-color: #95C11F !important;
    box-shadow: none !important;
  } */
.mobile_pay_row {
  padding-top: 40px;
}

.btn-group,
.btn-group-vertical {
  box-shadow: none;
}

.dinein_btn_grp2 {
  border-radius: 30px ! important;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  text-transform: none;
  text-align: center;
  font: normal normal medium 18px DM Sans;
  letter-spacing: 0px;
  color: #3A3A3A;
  font-size: 15px;
  font-weight: 700;
}

.dinein_btn_grp3 {
  border-radius: 30px ! important;
  background: #4b4b4b;
  opacity: 1 !important;
  color: #FFFFFF !important;
  font-weight: bolder;
  text-transform: none;
  text-align: center;
  letter-spacing: 0px;
  color: #3A3A3A;
  opacity: 0.5;
  font-size: 15px;
}

.dinein_btn_grp4:hover {
  color: black;
  background-color: #4b4b4b !important;
  border-radius: 30px;
}

.icon_dine_in {
  height: 20px;
}

.btn-primary:focus,
.btn-primary.focus,
.btn-primary:hover,
.btn-primary:hover {
  color: #fff;
  background-color: #4b4b4b;
}

.dinein_btn_grp4 {
  border-radius: 30px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  text-transform: none;
  /* border: 1px solid #222222; */
  text-align: center;
  font: normal normal medium 18px DM Sans;
  letter-spacing: 0px;
  color: #3A3A3A;
  font-size: 16px;
  font-weight: 700;
}

.dinein_btn_grp2:hover {
  color: #FFFFFF;
  background-color: #88B11A !important;
}

.detail_tab_content_btn_col {
  position: relative;
  /* bottom:30px; */
  /* right: 80px; */
}

.detail_tab_content_btn {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 184px;
  height: 57px;
  background: #95C11F 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  margin: 0;
}

.restaurant_cover2 {
  height: 210px;
  width: 435px;
  object-fit: cover;
}

.menu_item_list {
  width: 100%;
  height: 200px;
  object-fit: cover;
  /* height: 234px;*/
  border-radius: 30px;
}

.add_cart_image {
  /*object-fit: scale-down !important;*/
  object-fit: cover;
  /* height: 315px !important; */
  border-radius: 30px;
}

.react-datepicker__close-icon {
  display: none;
}

.confirm_order_image_row,
.confirm_order_image_row .card,
.confirm_order_image_row .confirm_order_image_cardbody,
.confirm_order_title1x .card-body,
.confirm_order_title2x .card-body {
  padding: 0;
  margin: 0;
}

.restaurant_cover2,
.restaurant_cover1 {
  border-radius: 20px;
  /* margin-right:182px; */
  float: right;
}

.tabcontent_custom {
  padding-top: 2em;
}

.sidebar_order_count {
  height: 48px;
  /* UI Properties */
  background: none;
  border-radius: 12px;
  opacity: 1;
  width: 75px;
  box-shadow: none;
}

.sidebar_order_count_all {
  text-align: right !important;
}

.restaurant_coverbody1,
.restaurant_coverbody2 {
  padding-left: 0;
  padding-right: 0;
}

.restaurant_cover_row {
  padding-left: 0;
  padding-right: 0;
  --mdb-gutter-x: 0;
}

.restaurant_cover_title {
  /* margin-left:-50px !important; */
  margin-bottom: 0;
}

.restaurant_cover_title_first {
  margin-bottom: 20px;
  float: right;
}

.Toastify__close-button.Toastify__close-button--light {
  display: none;
}

.detail_tab_content_mobile {
  font-size: 18px;
  padding: 11px
}


.custom_navbar_links_text {
  letter-spacing: 0px;
  color: #3A3A3A !important;
  /* text-align: left; */
  font-size: 16px;
  font-weight: 700;
  opacity: 1;
}

.navbar {
  height: 85px !important;
}

@media (min-width: 0px) {
  .navbar-expand-lg .navbar-nav {
    /* flex-direction: row-reverse; */
  }
}

.detail_tab_content2_mobile {
  color: #9BC22C;
  font-weight: bold;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #95C11F;
  opacity: 1;
  padding-top: 30px;
  padding-bottom: 30px;
}

.summary_totals {
  float: right;
}

.add_to_cart {
  background-color: #94c11e;
  width: 200px;
  height: 50px;
  font-family: 'DM sans';
  font-size: 15px;
  font-weight: 700;
  background: #95C11F 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
  color: #FFFFFF;
  box-shadow: none;
}

.modalcart {
  /* display:none; */
}

.detail_tab_addcart_image {
  padding: 0px 0px 0px 0px !important;
  margin: 0px 0px 0px 0px !important;
}

.filler_only {
  margin-top: 20px;
}

.add_to_cart:hover,
.sidebar_order_count:hover {
  background-color: #88B11A;
  box-shadow: none;
}

.toasterror {
  top: 40% !important;
}

.mc_placeorder_modal_body {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.detail_modal_inc_dec_btn {
  border: none;
  vertical-align: middle;
  opacity: 1;
}

.remove_cart {
  border: none;
  border-radius: 30px;
  color: #FFF;
  background-color: #d1717e;
  text-align: left;
  font-size: 13px;
  padding: 8px 15px 8px 15px;
}

.complete_processing {
  padding: 2.5rem;
}

.complete_processing_descr {
  margin: 0 100px;
  text-align: center;
  margin-bottom: 40px;
}

.complete_processing_amount {
  color: #95C11F;
  font-size: 20px;
  font-weight: 700;
}

.complete_processing_label {
  color: #000000;
  opacity: 1;
  font-size: 18px;
  font-weight: bold;
}

.processing_complete_title {
  font-size: 24px;
  color: #3A3A3A;
}

.complete_processing_footer div {
  padding-top: 5px;
  padding-bottom: 10px;
}

.payment_header_body {
  /* padding-left:2em;
    padding-right:2em; */
}

.complete_processing_footer {
  background: #F8F8F8 0% 0% no-repeat padding-box;
  display: block;
}

.complete_processing_receipt {
  margin-bottom: 15px;
}

.total_amount_labael {
  padding-top: 10px;
  margin-top: 30px;
}

.complete_processing_label_amount {
  font-size: 20px;
}

.delete_toggle_row {
  background-color: #F5F5F5;
  border-radius: 30px;
  padding: 7px 7px 7px 7px !important;
  margin: 10px 0px 0px 0px;
  --mdb-gutter-x: 0 !important;
  --mdb-gutter-y: 0 !important;
}

.remove_cartbody {
  padding: 0;
  margin: 0;
  padding-left: 2.5rem;
  /* margin-bottom:2.5rem; */
  /* float:left; */
  display: inline-flex;
  align-items: flex-end;

}

.notes_text_area {
  font-size: 16px;
  padding: 1em;
  width: 100%;
}

.detail_tab_clearcart button {
  float: right;
  margin: 30px 0px 0px 0px;
  border: none;
  border-radius: 20px;
  color: #FFF;
  background-color: #d1717e;
  text-align: left;
  font-size: 12px;
  font-weight: 700;
}

.detail_tab_clearcart .card-body {
  padding: 0;
}

.detail_modal_inc_dec_btn .fa-plus-circle,
.detail_modal_inc_dec_btn .fa-minus-circle {
  /* font-size: large; */
  color: #94c11e;
}

.detail_modal_counter {
  text-align: center;
}

.detail_modal_counter i.fa-minus-circle,
.detail_modal_counter i.fa-plus-circle {
  color: #94c11e !important;
  font-size: 25px;
  background-color: #FFFFFF;
  border-radius: 35px;
  /* vertical-align: sub !important; */
}

.detail_modal_counter .card-body {
  padding: 0;
}

.fa-2x {
  font-size: 1.2rem;
}


.sidebar_checkout {
  font-size: 16px;
  /* min-width: 325px; */
  text-transform: none;
  background-color: #6F9800;
  color: white;
  /* border:none;
    border-radius:10px; */
  height: 55px;
  /* UI Properties */
  border-radius: 40px;
  opacity: 1;
  box-shadow: none;
  ;
}

.pickup-help-text {
  margin: 20px 0px 10px 0px;
  padding: 15px 0px 10px 0px;
  font-size: 15px;
  font-weight: 700;
  border-top: 1px solid #F5F5F5;
  text-align: start;
}

.start_help_text {
  font-size: 15px;
  line-height: 19px;
}

.order_now_btn,
.order_now_btn:hover,
.order_now_btn:active,
.order_now_btn:focus:not(:focus-visible),
.order_in_advance,
.order_in_advance:hover,
.order_in_advance:active,
.order_in_advance:focus:not(:focus-visible) {
  background-color: #4b4b4b;
  border-radius: 30px;
  border: none;
  /* padding-right: 5em; */
  width: 100%;
  height: 50px;
  box-shadow: none;
}

.order_later_unselected {
  padding-bottom: 20px;
}

/* .order_now_btn{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #222222;
    border-radius: 15px;
    opacity: 0.5;
  } */
.advance_order {
  padding-top: 0;
}

.react-datepicker-wrapper {
  display: inline-block;
  width: 50%;
  /* margin-top:1em; */
}

.react-time-picker {
  float: right;
}

.react-datepicker-wrapper {
  float: left;
}

.tab-pane {
  padding-bottom: 1em;
}

.pickupdate {
  color: grey;
  border: none;
  font-size: 15px;
  letter-spacing: 0px;
  color: #3A3A3A;
  background-color: rgba(0, 0, 0, 0);
}

.pickupdate:focus {
  outline: none;
}

.pickuptime:focus {
  outline: none;
}

.react-datepicker__input-container {
  text-align: left;
}

.react-time-picker__wrapper {
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  color: grey;
}

.order_now_btn *,
.order_in_advance * {
  color: grey;
}

.order_now_btn i,
.order_in_advance i {
  float: right;
}

.breakdown_main {
  font-size: 15px;
}

.main_breakdown {
  padding-bottom: 0;
  padding-right: 0;
}

.extras_breakdown,
.breakdown_extra {
  padding-right: 0;
  padding-left: 0;
}

.breakdown_price {
  height: 24px;
  /* UI Properties */
  text-align: right;
  font: normal normal medium 18px/24px DM Sans;
  letter-spacing: 0px;
  color: #95C11F;
  opacity: 1;
  float: right;
  padding: 0;
}

.card-body.place_order_now_later {
  padding-left: 0;
  padding-right: 0;
}

.place_order_now {
  padding-top: 0;
  padding-bottom: 0;
}

.place_order_later {
  padding-top: 10px;
  padding-bottom: 0;
}

.sidebar_order_count {
  line-height: 0;
  width: 60px;
  height: 40px;
  /* UI Properties */
  text-align: center;
  /* font: normal normal medium 27px/35px DM Sans; */
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 18px;
  font-weight: 700;
}

.sidebar_checkout_card {
  padding: 0;
  background-color: #FFFFFF;
  border-radius: 0 0 20px 20px;
  border-top: none;
  padding-top: 0;
  border-color: #F8F8F8;
}

.din_ordre {
  min-width: 300px;
}

.sidebar_checkout_card_col {
  padding-top: 10px;
}

.sidebar_checkout_card_row {
  padding-bottom: 35px;
  overflow-y: auto;
  max-height: 400px;
}

.sidebar_checkout_row {
  /* --mdb-gutter-x: 0 !important; */
}

.sidebar_checkout_card p div {
  /* text-align: left; */
}

.breakdown_price {
  text-align: right;
}

.breakdown_main {
  text-align: left;
  min-width: 200px;
}

.sidebar_checkout_btn {
  padding: 0;
  margin: 0;
  --mdb-gutter-x: 0;
}

.sidebar_dinein_card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 20px 20px 0px 0px;
  opacity: 1;
  padding: 0px;
}

input[type=number]:focus {
  outline: none !important;
}

.header_container {
  padding-left: min(140px, 17%);
  padding-right: min(140px, 17%);
}

.detail_modal_inc_dec_btn {
  padding: 0;
}

.not_add_cart {
  background-color: #D9D9D9;
  float: right;
  border-radius: 30px;
  padding: 5px 7px 5px 7px;
  opacity: 1;
  display: inline-flex;
  align-items: center;
}

.detail_modal_counter .form-outline {
  display: inline-block;
  /* width: 50px; */
}

.detail_modal_counter .form-outline input {
  text-align: center;
}

/* .nav-link,.nav-link:hover{background: rgb(250, 250, 250) !important;} */
.detail_tabs_mobile .nav-link {
  font-size: initial;
  font-weight: bold;
  text-transform: none;
}

.tabs_show_hide {
  width: 20%;
}

.custom_navbar_links img:hover,
.custom_navbar_links img:active,
.custom_navbar_links img:visited,
.custom_navbar_links:hover,
.custom_navbar_links:active,
.custom_navbar_links:visited {
  background: rgb(250, 250, 250) !important;
  -webkit-tap-highlight-color: rgb(250, 250, 250) !important;
  transition: none !important;
  border: none !important;
}

.custom_navbar_links_text a:hover,
.custom_navbar_links_text a:active,
.custom_navbar_links_text a:visited {
  background: rgb(250, 250, 250) !important;
  -webkit-tap-highlight-color: rgb(250, 250, 250) !important;
  transition: none !important;
}

.custom_navbar_item a:hover,
.custom_navbar_item a:active,
.custom_navbar_item a:visited {
  background: rgb(250, 250, 250) !important;
  -webkit-tap-highlight-color: rgb(250, 250, 250) !important;
  transition: none !important;
}

.custom_navbar_item a:hover,
.custom_navbar_item a:active,
.custom_navbar_item a:visited {
  background: rgb(250, 250, 250) !important;
  -webkit-tap-highlight-color: rgb(250, 250, 250) !important;
  transition: none !important;
}

.left_btn_tab,
.right_btn_tab,
.mid_btn_tab {
  display: inline-block;
  border-style: none;
}

.left_btn_tab,
.right_btn_tab {
  width: 2%;
}

.payment_method_select {
  background: #F5F5F5;
  border-radius: 30px;
  opacity: 1;
  margin-bottom: 16px;
  padding: 10px 35px 10px 25px;
}

.payment_method_select>.card-title {
  margin-bottom: 0 !important;
  font-size: 13px;
  font-weight: 700;
}

.payment_method_select>.card-title input[type="radio"] {
  vertical-align: middle;
  border: black solid 1px;
  height: 18px;
  width: 18px;
  background-color: #6F9800;
}

.payment_method_select>.card-title input[type="radio"]:checked {
  background-color: #6F9800;
}

.tabcontent_custom {
  display: inline-block;
}

.dinecol {
  float: right;
  width: 30%;
}

.dinecol2 {
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0px;
  color: #3A3A3A;
  opacity: 1;
  /* min-width: 200px; */
  /* margin-left: 30px; */
}

.restaurant_cover_col2 {
  text-align: left;
}

.tab-content {
  width: 70%;
}

.menu_item_image {
  /* margin-left: 2em; */
}

.menu_details_all {
  padding: 0;
}

.mid_btn_tab {
  /* width:61%; */
  min-width: 980px !important;
  padding-left: 1%;
  padding-right: 1%;
  text-align: center;
  padding-bottom: 0;
  margin-bottom: 0 I !important;
}

.mb-3.mid_btn_tab {
  margin-bottom: 0 !important;
}

.mid_btn_tab li {
  display: inherit !important;
}

.detail_tabs_mobile .nav-link.active {
  color: #9BC22C !important;
}

.nav-link.active {
  border-color: #9BC22C !important;
}

.nav-link:hover {
  /* background-color: white !important; */
}

.custom_navbar_links {
  background: rgb(250, 250, 250) !important;
  -webkit-tap-highlight-color: rgb(250, 250, 250) !important;
  transition: none !important;
}

.detail_tabs_mobile .nav-link {
  color: #616161 !important;
}

.detail_tab_row_mobile {
  /* padding:1em; 
    padding-left: 1.5rem; */
  margin: 0;
  --mdb-gutter-x: 0rem;
}

.detailTabCartExtraCol {
  padding-left: 0;
}

.confirm_order_image {
  margin: 0;
  padding: 0;
  padding-left: .5rem;
  /* padding-bottom:2%; */
}

.confirm_order_body_price {
  padding-top: 30px;
  padding-bottom: 0;
}

.confirm_order_image_cardbody {
  padding-top: 40px;
  padding-bottom: 0;
}

.confirm_order_body_description {
  padding-top: 30px;
  padding-bottom: 0;
}

.detail_modal button {
  text-transform: initial;
}

.detail_modal button .fs-4 {
  font-size: 16px !important;
  vertical-align: middle !important;
  padding: 3px 0px 0px 0px;
}

.detail_modal .mt-4 {
  margin: 0px 0px 0px 0px !important;
}

.img-thumbnail-detail-mobile {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  object-fit: cover;
}

.img_cart_desktop_full {
  width: 100%;
  height: 350px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  object-fit: cover;
  border-radius: 30px 30px 0px 0px;
}

.img_cart_desktop {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  object-fit: cover;
  border-radius: 30px 30px 0px 0px;
}

.img-thumbnail-non-mobile img {
  border: 0 !important;
}

.detail_tab_row2_mobile .card-body {
  padding: 0;
}

.detail_tab_title_mobile .card-body {
  padding: 0 !important;
  text-align: left;
}

.detail_tab_content_btn_mobile {
  background-color: #9BC22C;
  color: white;
  border: none;
}

.detail_tab_checkout {
  text-align: center;
  position: fixed;
  bottom: 5%;
  width: 100%;
  margin: auto;
  z-index: 100;
}

.cart_icon {
  display: inline-flex;
}

.starring_outside {
  display: inline-block !important;
  padding: 0 2em 0 2em;
}

.detail_tab_checkout_card {
  background: #eeeeee;
  margin: auto;
  z-index: -99;
  width: 85%;
}

.detail_tab_checkout button {
  background-color: #9BC22C;
  text-transform: initial;
  border: none;
  padding-left: 2em;
  padding-right: 2em;
  /* opacity: .9; */
  /* width: 90%; */
  padding: 5%;
  color: white;
  /* font-size: 1em; */
}

.detail_login_input_form input {
  margin-bottom: 1em;
}

.detail_login_submit {
  background-color: #94c11e !important;
  font-size: 15.6px;
  width: 100%;
}

.sign_in_others {
  width: 100%;
  margin-bottom: 2em;
}

.forgotpassword a {
  color: #94c11e !important;
  cursor: default;
}

.detail_login_submit_card {
  padding: 2.2rem;
}

.add_card_button {
  width: 100% !important;
  height: 50px;
  border-radius: 25px;
  opacity: 1;
  background-color: #4b4b4b;
  color: white;
  font-size: 15px;
  font-weight: 700;
}

.add_card_button:hover,
.add_card_button:visited,
.add_card_button:active {
  background-color: #4b4b4b;
  color: white;
}

.order_button {
  width: 100% !important;
  height: 50px;
  /* UI Properties */
  /* background: #95C11F 0% 0% no-repeat padding-box; */
  border-radius: 25px;
  opacity: 1;
  background-color: #88B11A;
  color: white;
}

.order_button:hover,
.order_button:visited,
.order_button:active {
  background-color: #88B11A;
  color: white;
}

.order_button * {
  /* UI Properties */
  text-align: center;
  font-size: 15px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  margin-left: 10px;
}

.order_button {
  font-size: 15px;
}

.contact_number {
  background: #F8F8F8 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 20px 20px;
  opacity: 1;
}

.modal-footer {
  /* background: #F8F8F8 0% 0% no-repeat padding-box; */
  border-radius: 0px 0px 20px 20px;
  opacity: 1;
  padding: 0px 50px 30px 50px;
  justify-content: none !important;
  border: none;
}

.modal-header-confirm {
  display: block;
  padding: 50px 50px 50px 50px;
  text-align: center !important;
  font-family: 'Ubuntu', sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #000000;
}

.modal-footer-confirm {
  /* background: #F8F8F8 0% 0% no-repeat padding-box; */
  border-radius: 0px 0px 20px 20px;
  opacity: 1;
  padding: 50px 50px 50px 50px;
  justify-content: none !important;
  border: none;
}

.modal-footer-confirm * {
  --mdb-gutter-x: 0;
  --mdb-gutter-y: 0;
}

.mycontact {
  border-radius: 0px 0px 8px 8px !important;
  opacity: 1;
}

.mynotes {
  padding-top: 3em;
  padding-bottom: -3em;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.css-b62m3t-container {
  /* word:yeah; */
  width: 100%;
}

.css-1s2u09g-control {
  border-radius: 20px !important;
  height: 50px;
  background-color: #F5F5F5;
  padding: 0px 20px 0px 20px;
}

.css-1s2u09g-control:focus {
  border-radius: 20px !important;
}

.notes_text_area {
  font-size: 15px;
  border-radius: 20px !important;
  border: none;
  outline: none;
  background-color: #fff;
  border: solid 1px #aaa;
  padding: 20px 30px 20px 30px;
}

.css-1s2u09g-control {
  border-radius: 8px;
}

.mytable {
  /* UI Properties */
  color: black;
  border: none;
  text-align: left;
  font: normal normal medium 18px/24px DM Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.react-tel-input>.form-control {
  height: 50px;
  padding-left: 60px;
  ;
}

.react-tel-input unput {
  margin: 0
}

.modal-footer>* {
  margin: 0.25rem;
}

.modal-footer>* {
  margin: 0;
}

.mycontact {}

.table_number {
  width: 300px;
}

.react-tel-input {}

.dine_or_pickup {
  width: 40px;
  height: 40px;
  font-weight: bold;
  /* UI Properties */
  opacity: 1;
}

.confirm_order_labels {
  width: 100%;
  font-size: 15px;
  font-weight: 700;
}

.desktop_checkout_page .card-body {
  padding: 0px 0px 0px 0px;
  --mdb-gutter-x: 0;
  --mdb-gutter-y: 0;
}

.desktop_checkout_page .row {
  padding: 0px 0px 0px 0px;
}

.desktop_checkout_page .variationCard3 {
  padding: 0px 0px 0px 0px;
}

.modalcartbody {
  margin-bottom: 0;
  padding: 0px 50px 0px 50px;
}

.modal_confirm_order {
  padding-bottom: 0 !important;
}

.my_contact {
  margin-top: 0px;
  margin-bottom: 0px;
}

.my_table {
  margin-top: 0px;
  margin-bottom: 0px;
}

.paytitle {
  font-family: 'Ubuntu', sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
  color: #000000;
  margin-bottom: 0;
  padding-bottom: 40px;
}

.select_pay_method_title {
  padding-bottom: 0;
}

.enteranewcard {}

.placeorder3 {
  text-transform: none;
}

.detail_modal_inc_dec_btn {
  /* margin-top:-10px; */
}

.add_to_cart_plus_minus {
  margin-top: 0 !important;
}

.placeorder {
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: 700;
  ;
  /* UI Properties */
  background: #95C11F 0% 0% no-repeat padding-box !important;
  border-radius: 30px;
  opacity: 1;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 30px;
  box-shadow: none;
}

.placeorder2 {
  background: none !important;
  text-align: left;
  text-decoration: underline;
  font: 20px;
  letter-spacing: 0px;
  color: #3A3A3A;
  opacity: 1;
}

.placeorder2:hover {
  background: none !important;
  text-align: left;
  text-decoration: underline;
  font: 20px;
  letter-spacing: 0px;
  color: #3A3A3A;
  opacity: 1;
}

.dinecol2 {
  height: 37px;
  /* UI Properties */
  text-align: left;
  font: normal normal medium 28px/37px DM Sans;
  letter-spacing: 0px;
  color: #3A3A3A;
  opacity: 1;
  /* padding-bottom:60px; */
}

.sidebar_checkout {
  max-width: 100%;
  height: 26px;
  /* UI Properties */
  text-align: center;
  font: normal normal medium 20px/26px DM Sans;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  height: 50px;
  /* padding-bottom:60px; */
}

.placeholder:hover,
.sidebar_checkout:hover,
.placeholder:focus,
.sidebar_checkout:focus {
  background-color: #88B11A !important;
}

.signup_link {
  color: #94c11e !important;
  cursor: default;
}

.detailTabCartExtraBody {
  padding-left: 0;
  margin: 0;
  padding-top: 0px;
  padding-bottom: 0;
  text-align: left;
}

.detailTabCartVariationBody {
  margin: 0;
  padding-top: 30px;
  padding-bottom: 0;
  text-align: left;
}

.variation_name {
  padding-left: 1.5rem;
  /* padding-top: 20px; */
}

.variation_name.headline {
  text-align: left;
  font-size: 18px;
  color: #000000;
  font-weight: 700 !important;
  margin: 10px 0px 0px 0px;
  padding: 0px 0px 0px 15px;
}

.variation_options {
  border-radius: 30px;
  background-color: #F5F5F5;
  padding: 7px 10px 10px 20px !important;
  margin: 10px 0px 0px 0px;
}

.onePointFiveRem {}

.detailTabVariationBody {
  padding: 0;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: bold;
  --mdb-gutter-x: 0;
  --mdb-gutter-y: 0;
}

.detailTabCartExtraBody p {
  text-align: left;
  font-size: 18px;
  color: #000000;
  font-weight: 700 !important;
}

.detailTabCartExtraItemsBody {
  /* background:#eeeeee; */
  border-radius: 20px;
  padding: 0px 0px 0px 0px !important;
}

.detailTabCartExtraItems:first-child {
  margin: 0px 0px 0px 0px;
}

.detailTabCartExtraItems {
  width: 100%;
  --mdb-gutter-x: 0 !important;
  --mdb-gutter-y: 0 !important;
  border-radius: 30px;
  background-color: #F5F5F5;
  padding: 3px 7px 3px 20px !important;
  margin: 10px 0px 0px 0px;
}

.detailTabCartExtraItems .card {
  background-color: transparent;
}

.form-outline .form-control~.form-notch div {
  border: none;
}

.detailTabCartExtraItems2 {
  padding-left: 0;
}

.detailTabCartExtraItemsBodyTextBtn {
  color: #94c11e;
  font-size: 25px;
  background-color: #FFFFFF;
  border-radius: 35px;
}

.extraCounters {
  float: right !important;
}

.extraCountersButton {
  border: none;
  font-size: large
}

.extraPrice {
  float: right;
  font-size: 12px;
  font-weight: 700;
  color: #000;
  margin: 0px 10px 0px 0px;
  padding: 10px 0px 0px 0px;
}

.attribLabel .form-check {
  flex: 1;
  /* display: inline;
    width: 60%; */
  /* width: 83.33333333%; */
}

/* .mc_detail_tab_checkout_card .row>.mc_Checkout {
    width: fit-content;
  } */

.css_flex {
  display: flex;
  border-radius: 30px;
  background-color: #F5F5F5;
  padding: 7px 10px 10px 20px;
}

.css_flex_pad {
  padding-right: 1.5rem;
}

.attribPriceCol {
  text-align: right;
  padding-top: 4px;
}

.attribPriceCol .attribPrice {
  padding-right: 0px;
}

.variationxoption {
  flex: 1;
}

.attribPrice {
  font-size: 12px;
  color: #000000;
  font-weight: 700;
  padding: inherit;
  display: inline;
  /* width: 50%; */
  text-align: left;
}


.detailTabCartExtraItemsBodyText .form-outline {
  float: right
}

.form-check-label {
  flex: 1 1 10em;
  color: #000000;
  font-size: 12px;
  line-height: 36px;
  font-weight: 700;

}

@media (max-width: 991px) {
  .detail_pic1 {
    display: none;
  }

  .header_container .navbar-nav .w-100 {
    flex-direction: row-reverse;
  }

  .custom_navbar_links_text {
    padding-left: 1em !important;
  }
}

@media (min-width: 992px) {
  /* .detail_pic1{padding-bottom:4em;} */
  /* .detail_pic1 img{right:20px !important} */
}

@media (min-width: 1200px) {
  .detail_pic1 img {
    right: 100px !important
  }
}

.detail_intro {
  padding: 20px;
}

.details_col {}

.details_row h1,
.details_col .card-text {
  text-align: left;
  line-height: 1.4em;
}

.details_col .tab-content {
  text-align: center;
}

.dinein_btn_grp {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

.details_row .card {
  box-shadow: none;
}

.detail_tab_content_btn {
  /* margin-bottom: 4em; 
    width:50%; */
  background-color: #9BC22C;
  color: white;
  border: none;
}

.btn-group:active {
  box-shadow: none;
}

.btn-group:hover,
.btn-group-vertical:hover {
  box-shadow: none;
}

.restaurant-dtl-btn-radio1 {
  /* width: 200px;
      margin-left: 1.5em;
      box-shadow: none; */
}

.restaurant-dtl-btn-radio1 label[for="restaurant-dtl-btn-radio2"] {
  border-radius: 20px 0 0 20px;
  background-color: #9BC22C;
}

.restaurant-dtl-btn-radio1 label[for="restaurant-dtl-btn-radio3"] {
  border-radius: 0 20px 20px 0;
  background-color: #9BC22C;
}

.modal_confirm_order {
  padding: 30px 0px 30px 0px !important;
}

.checkout_footer {
  padding-top: 0;
}

.modal_confirm_order_subtotal span {
  float: left;
  letter-spacing: 0px;
  color: #3A3A3A;
  opacity: 1;
  font-size: 18px;
  font-weight: 700;
}

.modal_confirm_order_subtotal_price span {
  float: right;
  height: 31px;
  /* UI Properties */
  text-align: left;
  letter-spacing: 0px;
  color: #3A3A3A;
  opacity: 1;
  font-size: 18px;
  font-weight: 700;
}

.accordion-button:not(.collapsed){
  color:#4f4f4f
}

.accordion-button:not(.collapsed)::after{
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%234f4f4f%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e")
}

.accordion-body{
  padding: 10px;
}

@media (min-width: 576px) {
  .detail_tab_checkout_card {
    width: 60%;
  }

  .detail_tab_checkout button {
    background-color: #9BC22C;
    text-transform: initial;
    border: none;
    padding-left: 2em;
    padding-right: 2em;
    /* opacity: .9; */
    /* width: 90%; */
    padding: 1%;
  }

  .detail_tab_menu_item_name {
    /* white-space: nowrap; */
  }

  .detail_tab_content_btn_col {
    padding: 0;
  }

  .detail_pic1 img {
    z-index: 100 !important;
  }

  .detail_navbar {
    background-color: #9BC22C;
    height: 500px !important;
    min-height: 500px;
    max-width: 100% !important;
    /* position: absolute; */
    box-shadow: none;
    background: linear-gradient(rgb(72, 0, 72, 0.8), rgb(192, 72, 72, 0.8));
  }

  .detail_intro {
    display: inline-block;
    width: 40%;
    padding: 8em;
    padding-top: 12em;
    padding-bottom: 2em;
    color: white;
  }

  .detail_intro>h1 {
    font-size: 4.5em;
    font-weight: 400;
    font-family: 'DM Sans';
  }

  .detail_tabs .nav-link {
    color: #9BC22C !important;
    font-size: large;
    text-transform: none;
  }

  .detail_pic1 img {
    /* float:right; */
    position: absolute;
    right: 100px;
    width: 500px !important;
    border-radius: 20px;
    margin-top: -120px;
    /* height:500px !important; */
  }

  .detail_tab_content {
    font-size: x-large;
    color: #3a3a3a !important;
    text-align: left;
    font-weight: 700;
  }

  .detail_tab_content2 {
    text-align: left;
    color: #9BC22C;
    font-weight: bold;
  }

  .detail_tab_content_descr {
    text-align: justify;
    font-size: 15px;
  }


}

.detail_tab_content_btn,
.detail_tab_content_btn:visited,
.detail_tab_content_btn:active {
  background-color: #4b4b4b !important;
  color: white !important;
  border: none;
}



/* Carousel */
.container.carousel_container {
  /* max-width: 1020px; */
  /* position: sticky;
      top: 10px; */
  /* background-color: #95C11F;
      z-index: 9999; */
}

.stickyHeaderOn,
.mc_sticky {
  z-index: 9;
}

.stickyHeaderOn {
  /* margin-top:20px; */
}

.stickySideBar {
  margin-top: 30px;
  top: 90px;
}

.carousel_container>div>div.react-multi-carousel-list.container {
  background-color: rgba(255, 255, 255, 1);
}

.stickyHeaderOn>div>div>div.react-multi-carousel-list.container {
  /* background-color: #95C11F; */
  /* background-color: rgba(255, 255, 255, 1); */
  background-color: white;
  /* z-index: 9999999 !important; */
  /* border-radius:0 0 20px 20px; */
}

.carousel_container .react-multi-carousel-list {
  display: block;
}

.stickyHeaderOn .right_btn_tab {
  /* z-index: 9999; */
}

.our_menu_card {
  /* z-index: -9999; 
    width: 466px; 
	*/
}

.order_button {
  /* z-index: -9999;  */
}

.carousel_container .react-multi-carousel-list.container {
  max-width: 1000px;
  padding: 10px 0;
  height: 69px;
  max-height: 69px;
  min-height: 69px;
  border-bottom: 1px solid rgb(235, 226, 226);
  /* background-color: rgba(0, 0, 0, 0); */
  /* margin-left:1.5rem; */
  margin-left: 1.0rem;
}

.carousel_container .react-multi-carousel-item {
  width: 200px;
  min-width: 200px;
  text-align: center;
}

.carousel_container .react-multi-carousel-item a {
  letter-spacing: 0px;
  color: #3A3A3A;
  font-size: 18px;
  font-weight: 700;
}

/* MENU CARD STYLES BEGIN */
.selected_category,
.Mui-selected {
  color: #FFFFFF !important;
  background-color: #4b4b4b !important;
}

.MuiTabs-scroller {
  border: none !important;
}

.left_btn_tab_float,
.right_btn_tab_float {
  height: 50px !important;
}

.scrollspytabs>div>.hashighercat {
  height: 70px !important;
  padding: 0;
}

.scrollspytabs>div>.hashighercat {
  z-index: 9;
  top: 70px !important;
}

.MuiTabs-fixed {
  margin-top: 5px;
  overflow: auto !important;
  scrollbar-width: none !important;
}

.MuiTabs-fixed::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.nav-item-carousel {
  color: black;
}

.nav-item-carousel {
  text-align: center;
}

.carousel_container {
  /* max-width:100%; */
  margin: 0;
}

.carousel_container .carousel-button-group {
  /* position: absolute; */
  top: 0;
  left: -30px;
  width: 88%;
  /* z-index: -9999; */
}

.carousel_container .carousel-button-group .left_btn_tab {
  float: left;
  margin-top: -44px;
  margin-left: -40px;
}

.carousel_container .carousel-button-group .right_btn_tab {
  float: right;
  margin-top: -44px;
  margin-right: 100px;
}

.carousel_container .react-multi-carousel-list.container {
  padding-top: 30px;
}

.mc_carousel2,
.mc_dinordre,
.mobile_version_bot,
.mobile_container {
  display: none;
}

.mobile_add_cart,
.mobile_add_cart_footer,
.mc_checkout_page,
.mobile_modals,
.mc_total_amount_labael {
  display: none;
}

.highercatcontainer {
  position: sticky;
  top: 0;
  display: flex;
  flex-wrap: nowrap;
  z-index: 99;
  justify-content: left;
  padding: 0px 0px 0px 0px;
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  background-color: #FFF; 

}

.highercat {
  margin: 10px 10px 10px 10px;
  background-color: #F5F5F5;
  border-radius: 30px;
  cursor: pointer;
  padding: 15px 30px;
  color: #4f4f4f;
  font-weight: 700;
  min-width: 170px;
}

.highercatcontainer .active {
  background-color: #4b4b4b;
}
 

/* ARROW DOWN HIGHER CATEGORY*/
.arrow-down-cat {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 19px solid transparent;
  border-right: 19px solid transparent;
  border-top: 19px solid #95c11f;
}

@media screen and (max-width: 1024px) {
  .arrow-down-cat {
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-top: 13px solid #95c11f;
  }
}



._scrollspy {
  margin-top: 2rem;
}

.MuiTab-root {
  font-family: 'DM Sans';
  letter-spacing: normal;
  max-width: none;
  min-width: none;
}

.MuiTab-wrapper div {
  font-family: 'DM Sans';
  font-weight: 700;
  line-height: 1.2em;
}

button.MuiButtonBase-root {
  height: 50px;
  background-color: #F5F5F5;
  border-radius: 30px;
  border: none;
  padding: 15px 25px 15px 25px;
  max-width: none;
}

.MuiTab-textColorInherit {
  opacity: 1 !important;
}

/* Remove all MDB gutters on the Add Product Modal*/
.add_product_modal * {
  --mdb-gutter-x: 0rem;
  --mdb-gutter-y: 0rem;
}

.mobile_add_cart {
  --mdb-gutter-x: 0rem;
  --mdb-gutter-y: 0rem;
  padding: 0;
}

.add_product_modal .card {
  background-color: transparent !important;
}

/* Add product screen */
.desktop_add_cart {
  padding: 0px;
  --mdb-gutter-x: 0rem;
}

.desktop_add_cart row {
  padding: 0px;
  --mdb-gutter-x: 0rem;
}

.desktopdetail_tab_row_mobile {
  --mdb-gutter-x: 0rem;
}

.form-outline .form-control {
  background-color: transparent;
  border: none;
  font-size: 15px;
  font-weight: 700;
  padding: 0;
  margin: 5px 0px 0px 0px;
}

.number_toggle_cart {
  margin: 0px 0px 0px 0px !important;
  width: 30px;
}

.desktop_add_cart_footer {}

.nomenu {
  font-size: 20px;
  font-weight: bold;
}

.left-btn-cat{
  margin-left: -20px;
}

.right-btn-cat{
  margin-right: -3px;
}

.ml13{
  margin-left: 13px;
}

.payment-selection{
  border: solid 2px transparent;
}

.payment-selection-active{
  border: solid 2px #95c11f;
}

.requiredTxt{
 color: #aaa;
 font-size: 13px;
}

.modal.show .modal-dialog-centered {
  display: flex !important;
  /* background-color: #00000070; */
  justify-content: center;
}

/* small devices */
@media (max-width: 576px) {
  .variationxoption {
    font-size: 14px;
  }

  .custom_profile {
    display: none;
  }

  .schedule_section {
    margin-bottom: 20px !important;
  }

  .mc_counter_add_cart .form-outline input {
    text-align: center;
  }

  .category_intro {
    text-align: left;
    padding-left: 0;
    padding-top: 2.5rem;
    padding-bottom: 0;
  }

  .schedule {
    font-size: small !important;
  }

  .schedule_section * {
    font-size: 16px !important;
  }

  .checkout_mobile_modals,
  .checkout_mobile_modals:hover {
    background-color: #95C11F !important;
    border-color: #95C11F !important;
    color: white;
  }

  .payment_headerx h5.mobile_modals {
    background-color: none !important;
    border-color: none !important;
    color: initial;
  }

  .restaurants_col div {
    text-align: center;
  }

  .desktop_modals {
    display: none;
  }

  .variationtitle {}

  .detailTabCartExtraItems {
    /* padding-left: 1.5rem !important;
      padding-right: 1.5rem !important; */
  }

  .dinein_takeout_mobile_only {
    display: block;
  }

  .extraPriceDisplay {
    /* margin: 0 auto !important; */
    margin-left: 30px;
  }

  .detailTabCartVariantItemsBody {
    /* background-color: whitesmoke; */
    padding: 7px 0px 0px 0px !important;
  }

  .detailTabCartExtraItemsBodyText {
    width: 100%;
    display: inline-flex !important;
    align-items: center;
  }

  .detailTabCartExtraItemsBodyText .form-outline {
    position: absolute;
    right: 3rem;
  }

  .extraCountersA {
    /* text-align: center; */
    position: absolute;
    right: 1.5rem;
    font-size: large;
  }

  .detailTabCartExtraItemsBodyTextBtn {
    font-size: 25px !important;
  }

  .extraCountersC {
    /* text-align: center; */
    position: absolute;
    right: 6.5rem;
    font-size: large !important;
  }

  .not_add_cart .detail_modal_inc_dec_btn {
    font-size: large !important;
  }

  .mc_detailTabCartVariantItems,
  .mc_detailTabCartVariantItemsCol {
    padding-left: 0;
    padding-right: 0;
    --mdb-gutter-x: 0 !important;
    --mdb-gutter-y: 0 !important;
    display: flex;
  }

  .variation_options:last-of-type {
    padding-bottom: 10px !important;
  }

  .mc_detailTabCartVariantItems {
    padding-right: 0;
    padding-left: 1.5rem;
  }

  .detailTabCartVariantItemsBodyText {}

  .variationCard4 {
    padding-left: 2rem;
  }

  .no_padding_left {
    padding-left: 0;
  }

  .mobile_add_cart,
  .mobile_add_cart_footer,
  .mc_checkout_page,
  .mobile_modals,
  .mc_total_amount_labael {
    display: initial;
  }

  .desktop_add_cart,
  .desktop_add_cart_footer,
  .total_amount_labael {
    display: none !important;
  }

  .mc_add_cart_adj {
    display: inline-block;
    width: 100%;
    margin: auto;
  }

  .mc_carousel2,
  .mc_dinordre,
  .mobile_version_bot,
  .mobile_container {
    display: block;
  }

  .mobile_version_bot {
    position: absolute;
    left: 0;
  }


  .mobile_version_bot {
    width: 100%;
    object-fit: cover;
  }

  .mc_carousel2 {
    /* padding-top:5%; */
  }

  .non_mobile {
    display: none;
  }

  .mobile_container {
    position: relative;
    top: 0px;
    background: white;
    border-radius: 20px;
    margin-top: 0px;
  }

  .mc_body {
    padding: 20px;
  }

  .mc_restaurant_her_cr {
    font-weight: 700;
    margin-top: 0px;
  }

  .tab-content {
    width: 100%;
  }

  .tabcontent_custom {
    padding: 1em;
  }

  .menu_item_list {
    width: 100px;
    height: 80px;
  }

  .menu_item_image {
    margin-left: 0;
  }

  .mc_image_menu_col {
    padding-left: 0;
  }

  .mc_order_button {
    width: 100% !important;
    background: #95C11F 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    text-transform: none;
    padding-right: 0;
    padding-left: 0;
  }

  .detail_tab_content_descr_menu {
    padding-left: 1em;
  }

  .mc_order_button * {
    text-align: center;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }

  .mccol_5 {
    padding: 0px 0px 0px 0px;
  }

  .detail_tab_content2 {
    text-align: left;
    color: #3a3a3a;
    font-weight: bold;
    font-size: initial;
  }

  .mc_menu_title {
    font-weight: bold;
    font-size: initial;
  }

  .detail_tab_content_descr {
    padding-top: 1em;
  }

  .dinein_btn_grp4,
  .dinein_btn_grp3,
  .dinein_btn_grp2,
  .dinein_btn_grp1 .order_in_advance {
    font-size: initial !important;
  }

  .icon_dine_in {
    font-size: initial;
  }

  .dinein_takeout {
    margin: auto;
  }

  .dinein_takeout>.card {
    box-shadow: none;
  }

  .carousel-button-group {
    margin-top: -40px;
  }

  .left_btn_tab,
  .right_btn_tab {
    width: auto;
  }

  .carousel-button-group .right_btn_tab {
    float: right;
    margin-top: 0;
    margin-right: -1.5em;
    padding: 0 10px 0 10px
  }

  .carousel-button-group .left_btn_tab {
    float: left;
    padding-left: 0.5em;
    margin-left: -1.5em;
  }

  .react-multi-carousel-item {
    /* width: 200px;
      min-width: 200px;*/
    text-align: center;
  }

  .react-multi-carousel-item a {
    letter-spacing: 0px;
    color: #3A3A3A;
    font-size: 16px;
  }

  .selected_category,
  .Mui-selected {
    color: #FFFFFF !important;
    background-color: #95C11F !important;
  }

  .react-multi-carousel-list.container {
    border-bottom: 1px solid rgb(235, 226, 226);
    background-color: rgba(255, 255, 255, 1);
  }

  .mc_dinordre {
    display: none;
  }

  .mc_carousel2 {
    width: 95%;
    margin: auto;
  }

  .mc_detail_tab_checkout {
    text-align: center;
    position: fixed;
    bottom: 5%;
    width: 100%;
    margin: auto;
    z-index: 100;
  }

  .mc_detail_tab_checkout button {
    background-color: #9BC22C;
    text-transform: initial;
    border: none;
    color: white;
    /* padding-left: 0;
      padding-right: 0;  */
    /* padding: 1%;  */
    border-radius: 120px;
    height: 45px;
    width: 175px;
    font-size: 16px;
    font-weight: 700;
  }

  .mc_detail_tab_checkout_card {
    background: white;
    margin: auto;
    z-index: 9999;
    width: 90%;
    border-radius: 0px;
  }

  .modal-dialog {
    width: auto;
  }

  .modal-content {
    width: auto;
  }

  .mc_extraName,
  .mc_extraPrice {
    font-size: small;
  }

  .mc_extraName {
    font-weight: bold;
  }

  .mc_extraPrice {
    font-weight: light;
  }

  .mc_extraBody,
  .mc_extraCounters {
    display: inherit;
  }

  .mc_extraCounters {
    /* float:right; */
    margin: 0;
    padding: 0;
  }

  .mc_extraBody {
    /* background-color: whitesmoke; */
    display: inline-flex;
    align-items: center;
    border-radius: 10px;
    padding: 1.5rem;
  }

  .mc_Checkout {
    /* display: inline-flex;
      align-items: center; */
    display: block;
    text-align: right;
  }

  .extraCountersButton {
    margin: 0;
    padding: 0;
  }

  .mc_extraInputs {}

  .mc_extraInputsDtl {
    text-align: center;
    /* padding-top: 0 !important;
      padding-bottom: 0 !important; */
  }

  .mc_extraRow {
    margin-bottom: 1em;
    border-radius: 30px;
    background-color: #F5F5F5;
    padding: 5px 10px 5px 20px;
  }

  .mc_extraCard {
    box-shadow: none;
  }

  .mc_variationCard {
    /* background-color: whitesmoke; */
    border-radius: 10px;
  }

  .mc_variant_body {
    background-color: whitesmoke;
    padding: 0;
  }

  .whitesmoke_effect {
    /* margin-bottom:1.5em; */
  }

  .whitesmoke_effect {
    box-shadow: none;
    padding-bottom: 1.5em;
    background-color: whitesmoke;
    /* border-radius:10px; */
  }

  .remove_box_shadow {
    box-shadow: none;
  }

  .mc_extras_variants {
    font-size: 18px;
    font-weight: 700;
    color: #000;
    padding: 7px 0px 0px 0px;
  }

  .mc_variationCard {
    box-shadow: none;
    margin: 0px 0px 0px 0px;
  }

  .detailTabCartVariantItemsBody {
    padding-left: 0;
    padding-bottom: 0;
  }

  .detailTabCartVariantItemsBodyText>div>div>label {
    font-size: 14px !important;
  }

  .detailTabCartVariantItemsBodyText>div>div {
    height: 50px;
  }

  .detailTabCartVariantItemsBodyText>div>label>label {
    font-size: 14px !important;
  }

  .detailTabCartVariantItemsBodyText>div>label {
    height: 50px;
  }

  .attribPrice * {
    display: inline-flex;
    align-items: center;
  }

  .attribPrice,
  .variationx_name,
  .detailTabCartVariantOptions>div label {
    font-size: 14px;
  }

  .add_to_cart_plus_minus {
    font-size: large;
    width: auto;
  }

  .mc_counter_add_cart {
    display: inherit;
    width: 36%;
  }

  .mc_menu_col5 {
    margin-left: 20px;
  }

  .mc_detail_tab_content_btn_col>.form-outline {
    height: fit-content;
    max-width: 40px;
    margin-left: 0.2em;
    margin-right: 0.2em;
  }

  .mc_counter_add_cart>.form-outline>input {
    /* padding-top:0;
      padding-bottom:0; */
  }

  .btn-success.add_to_cart {
    font-size: small;
  }

  .mc_menu_list_height_add_cart {}

  .mc_tabcontent_custom_col5 {
    padding-left: 0;
  }

  .add_to_cart {
    height: 50px;
    padding: 0.625rem 1.5rem 0.5rem 1.5rem;
    width: 100%;
    border-radius: 30px;
    font-size: 15px !important;
    font-weight: 700;
    box-shadow: none;
  }

  .mc_detail_tab_checkout_card {
    margin-bottom: 1em;
  }

  .header_container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .mc_detail_tab_checkout_card>.card-body {
    padding: 0;
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .mc_detail_tab_checkout_card {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .mc_checkout_items {
    text-align: left;
    line-height: 1;
  }

  .mc_checkout_items_price {
    font-weight: bold;
  }

  .mc_checkout_items_items {
    font-size: small;
  }

  .modal_confirm_order_subtotal span,
  .modal_confirm_order_subtotal_price span,
  .my_contact,
  .my_table,
  .confirm_order_labels {
    font-size: 15px;
  }

  .checkout_footer {
    padding: 0px 30px 0px 30px;
  }

  .hr_rule_desk_top_hr_rule {
    border-top: 1px solid whitesmoke;
  }

  .hr_rulerow {
    padding-left: 2em;
  }

  .detailTabCartVariantOptions {
    padding-top: 10px;
  }

  .variationx_name {
    padding-top: 10px;
  }

  .select_pay_method_title {
    font-size: 16px;
  }

  .placeorder2 {
    font-size: 16px;
  }

  .paytitle {
    font-size: 16px;
  }

  .payment_method_select>h5,
  .tipsbody>h5 {
    font-size: initial;
  }

  .processing_complete_title {
    font-size: initial;
  }

  .payment_headerx>h5,
  .complete_processing_amount {
    font-size: 1.2em;
    font-weight: 900;
    color: #000;
  }


  .complete_processing_label {
    margin: 0;
    font-size: 16px
  }

  .complete_processing_footer {
    /* padding: 10px; */
  }

  .mc_total_amount_labael div {
    display: inline-block;
  }

  .mc_total_amount_labael:nth-child(2) {
    text-align: right !important;
  }

  .react-multi-carousel-item:not(.react-multi-carousel-item--active) a {
    display: none;
  }

  .mc_carousel2>.react-multi-carousel-list.container {
    min-height: 3rem;
  }

  .mc_menu_list_height {
    margin-left: 0;
  }

  .mc_order_choices {
    width: 100%;
    padding-top: 1em !important;
    padding-bottom: 1em;
  }

  .mc_order_choices_now {
    font-weight: bold;
    font-size: 16px;
  }

  .mc_order_choices_advance {
    font-size: small;
  }

  .mc_order_choicescol {
    display: inline-block;
    text-align: left;
    /* position: relative; 
      top: -1em; */
  }

  .mc_order_choicescolicon {
    position: relative;
    top: -.2em;
    font-size: x-large
  }

  .mc_order_choices_icon,
  .mc_order_choicescol {
    text-align: right;
  }

  .mc_order_choicescol {
    text-align: left;
  }

  .mc_order_choicescolicon {
    text-align: right;
  }

  .mc_order_choices_btn {
    color: #4f4f4f;
    border: none;
    border-top: 1px solid #4f4f4f;
    border-bottom: 1px solid #4f4f4f;
    padding: 1em 0;
    border-radius: unset;
  }

  .mc_order_choices_btn:hover {
    color: #4f4f4f;
    border: none;
    border-top: 1px solid #4f4f4f;
    border-bottom: 1px solid #4f4f4f;
    padding: 1em 0;
    border-radius: unset;
  }

  .mc_placeorder {
    width: 100%;
    margin: 0;
    padding: 1em !important;

  }

  .order_in_advance div:nth-child(1),
  .order_now_btn div:nth-child(1) {
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 16px;
    text-transform: none;
  }

  .mc_button_orderfrequency {
    background: #95C11F 0% 0% no-repeat padding-box !important;
    border-radius: 30px;
    height: 50px;
    box-shadow: none;
    font-size: 16px;
    font-weight: bold;
    text-transform: none;
  }

  .mc_button_orderfrequency_row {
    width: 100%;
    margin: auto;
    padding: 1.5em;
  }

  .mc_placeorder_modal_body {
    padding: 0;
    width: 100%;
  }

  .place_order_now {
    padding-top: 0;
  }

  .pickuptime_p,
  .pickupdate_p {
    width: 48%;
    padding: 10px 20px 10px 20px;
    display: inline-block;
    background-color: #F5F5F5;
    border-radius: 20px;
  }

  .mc_order_schedule {
    font-size: initial;
  }

  .mytable {
    margin-left: 0;
    padding-left: 0;
  }

  .css-1s2u09g-control,
  .css-2613qy-menu,
  .css-1pahdxg-control {
    /* width: calc(100% - 3rem); */
    margin: auto;
    background-color: #F5F5F5;
  }

  .css-2613qy-menu * {
    color: red !important
  }

  #react-select-3-listbox {
    width: calc(100% - 3rem);
    margin-left: 1.5rem !important
  }

  /* .css-1s2u09g-control,.css-b62m3t-container{
      width: calc(100% - 3rem ); 
      margin: auto;
    } */
  .react-tel-input .form-control {
    width: 100%;
  }

  .dinein_takeout {
    width: 100%;
  }

  .mc_details_col {
    padding: 0;
  }

  .dinein_selected {
    margin-bottom: 0;
  }

  .mytable .validation_error {
    margin-left: 0 !important;
  }

  .mynotes {
    padding-top: 20px;
  }

  .variationxoption2 {
    text-align: right;
  }

  .variantMarginBottom {
    margin-bottom: 20px;
  }

  .variantMarginBottom:last-child {
    margin-bottom: 0px;
  }

  .details_row {
    padding-top: 0; 
  }


}

@media (min-width: 1400px) and (max-width: 1700px) {
  .menu_item_list {
    max-width: 220px;
  }
}

@media (min-width: 1024px) {
  .dinecol {
    width: 25%;
    border-left: 1px solid #F5F5F5;
    padding: 0px 0px 0px 50px;
  }

  #react-select-2-listbox {
    width: calc(100% - 1.5rem);
  }

  .mc_extraBody {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .toggle_holder {
    background-color: #d9d9d9;
    border-radius: 30px;
    display: inline-block;
    float: right;
    padding: 0 5px;
  }

  .extraCountersButtonNew .fa-minus-circle,
  .extraCountersButtonNew .fa-plus-circle {
    color: #94c11e !important;
    font-size: 25px;
    background-color: #FFFFFF;
    border-radius: 35px;
    /* vertical-align: sub !important; */
  }

  .variationCard4 .attribPriceCol {
    padding-right: .8rem;
  }

  .detailTabCartExtraItems2 {
    padding-right: 0;
  }

  .extraCountersButtonNew {}

  .extraCounters {
    text-align: center !important;
  }

  .toggle_holder {
    display: inline-block;
    background-color: #D9D9D9;
    float: right;
    border-radius: 30px;
    padding: 0px 5px 0px 5px;
  }

  .amount_wrap {
    position: relative;
    display: block;
    margin: -43px auto 0px auto;
  }

  .amount_text {
    text-align: center;
    padding: 0px;
    margin: 0px;
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  }

  .toggle_holder_amount {
    display: block;
    width: 100px;
    background-color: #F5F5F5;
    border-radius: 30px;
    padding: 0px 5px 3px 5px;
    margin: 0px auto 0px auto;
  }

  .toggle_holder_amount .add_to_cart_col {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .toggle_holder_amount .add_to_cart_col .btn {}

  .toggle_holder_amount .form-outline .form-control {
    margin: 0px 0px 0px 0px;
  }

  .toggle_holder_amount .add_to_cart_col input {
    margin: 7px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    width: 2rem;
    height: 20px;
  }

  .toggle_holder_amount .add_to_cart_col i {
    font-size: 25px;
    width: auto;
  }

  .toggle_holder_amount .detail_modal_inc_dec_btn {
    top: 3px;
    height: 30px;
    width: 30px;
  }

  .extraCountersButton {
    padding: 5px 0px 0px 0px;

  }

  .attribPrice,
  .variationxoption2price {
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
  }

  .variationxoption2 {
    text-align: right;
    /* padding-right: 1.5rem; */
  }
}

@media (min-width: 1024px) and (max-width: 1399px) {
  .restaurant_cover1_col {
    right: 1%;
    padding-left: 0 !important;
  }

  .restaurant_cover1 {
    height: 380px;
    width: 100%
  }

  .restaurant_cover2 {
    width: 100%;
    height: 180px;
  }

  .mobile_css_spacer {
    width: 0;
    margin: 0;
    padding: 0;
  }

  .restaurant_cover1_col,
  .restaurant_cover2_col {
    width: 33%;
    --mdb-gutter-x: 0 !important;
  }

  .restaurant_cover1_col *,
  .restaurant_cover2_col * {
    --mdb-gutter-x: 0 !important;
  }

  .restaurant_cover2_col {
    margin-left: 0 !important;
    padding-left: 0 !important;
    --mdb-gutter-x: 0 !important;
  }


  /* .mobile_css_col2, .category_intro {
      padding-left: 3rem;
    } */

  .mc_detail_tab_checkout {
    display: none;
  }

  .sidebar_checkout {
    min-width: 100px;
    width: 100%;
  }

  .sidebar_dinein_card,
  .sidebar_checkout_card {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .main_breakdown {
    margin-right: 0;
    padding-left: 0;
    margin: auto;
  }

  .breakdown_main>strong {
    text-transform: uppercase;
  }

  .topbanner {}

  .header_container {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .topbanner>.details_col {
    padding-left: 2rem;
  }

  .breakdown_price {
    padding-right: 1rem;
  }

  .breakdown_main {
    padding-right: 0;
    min-width: 150px;
  }

  .extras_breakdown {
    margin: auto;
    padding-left: 1rem;
  }

  .mobile_css_dkk {
    display: none;
  }

  .breakdown_extra {
    min-width: 150px;
  }

  .sidebar_checkout_card_col {
    padding-left: 0;
    padding-right: 0;
  }

  .sidebar_order_count_all,
  .icon_dine_in {
    display: none;
  }

  .main_breakdown_siblings {
    padding-left: 0;
    padding-right: 0;
    margin: auto;
  }
}

@media (max-width: 1400px) {
  .category_intro .card-title {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 20px;
    text-transform: none;
  }

  .dinecol {
    padding: 0px 0px 0px 20px;
  }
}

@media (min-width: 1400px) and (max-width: 1800px) {

  .details_row,
  .header_container {
    padding-left: min(80px, 8%);
    padding-right: min(80px, 8%);
  }

  .restaurant_cover1_col {
    position: relative;
    right: 0;
  }

  .restaurant_cover1 {
    height: 380px;
    width: 480px;
    object-fit: cover;
    margin-left: 182px;
  }

  .restaurant_cover2 {
    height: 180px;
    width: 300px;
  }

  .carousel_container .react-multi-carousel-list.container {
    max-width: 780px;
  }

  .carousel_container .carousel-button-group .right_btn_tab {
    margin-right: 200px;
  }

  .dinecol {
    float: right;
    width: 28%;
  }

  .din_ordre {
    min-width: 180px;
  }

  .sidebar_order_count_all {
    position: relative;
    width: 40px;
    /* top: -2.5rem; */
  }

  footer {
    position: relative;
    z-index: -9999;
  }

  .sidebar_checkout {
    min-width: 100%;
  }
}

@media (min-width: 1600px) and (max-width: 1800px) {
  .carousel_container .react-multi-carousel-list.container {
    max-width: 970px;
  }

  .carousel_container .carousel-button-group .right_btn_tab {
    margin-right: 100px;
  }
}

/* medium size */
@media (min-width: 577px) and (max-width: 1023px) {

  .details_row {
    padding-top: 0; 
  }

  .mobile_css_pad2 .detailTabCartVariantItemsBody {
    padding-left: 0;
  }

  .desktop_modals {
    display: none;
  }

  .extraPrice {
    padding-left: 0 !important;
  }

  .mc_order_button {
    background-color: #95C11F !important;
  }

  .mc_order_button:active,
  .mc_order_button:hover {
    background-color: #95C11F;
    color: white;
  }

  .variationtitle {
    text-transform: uppercase;
  }

  .detailTabCartVariantItems {
    padding-left: 3rem;
  }

  .detailTabCartExtraItems {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .extraPriceDisplay {
    margin: 0 auto !important;
  }

  .detailTabCartVariantItemsBody {
    /* background-color: whitesmoke; */
    padding-bottom: 1em;
    /* border-radius: 10px; */
  }

  .detailTabCartExtraItemsBodyText {
    width: 100%;
    display: inline-flex !important;
    align-items: center;
  }

  .detailTabCartExtraItemsBodyText .form-outline {
    position: absolute;
    right: 3rem;
  }

  .extraCounters {
    text-align: center;
  }

  .extraCountersA {
    /* text-align: center; */
    position: absolute;
    right: 1.5rem;
    font-size: large;
  }

  .detailTabCartExtraItemsBodyTextBtn {
    font-size: 1em !important;
  }

  .detailTabCartVariantItemsBody {
    padding-left: 1.5rem;
  }

  .extraCountersC {
    /* text-align: center; */
    position: absolute;
    right: 6.5rem;
    font-size: large !important;
  }

  .not_add_cart .detail_modal_inc_dec_btn {
    /* font-size: large !important; */
  }


  .dinein_takeout_mobile_only {
    display: block;
  }

  .mc_detailTabCartVariantItemsCol .attribLabel {
    padding-left: 1.5rem;
  }

  .mc_detailTabCartVariantItemsCol {
    padding-right: 0;
  }

  .mc_detailTabCartVariantItems {
    padding-left: 0;
    margin: auto;
  }

  .mobile_version_bot {
    object-fit: cover;
  }

  .mc_restaurant_dtl {
    max-width: 577px;
    margin: auto;
  }

  .mc_carousel2>.react-multi-carousel-list.container {
    min-height: 3rem;
  }

  .mobile_container {
    max-width: 1200px;
    ;
    padding-left: min(140px, 17%);
    padding-right: min(140px, 17%);
  }

  .mobile_add_cart,
  .mobile_add_cart_footer,
  .mc_checkout_page,
  .mobile_modals,
  .mc_total_amount_labael {
    display: initial;
  }

  .desktop_add_cart,
  .desktop_add_cart_footer,
  .total_amount_labael {
    display: none !important;
  }

  .mc_add_cart_adj {
    display: inline-block;
    width: 100%;
    margin: auto;
  }

  .mc_carousel2,
  .mc_dinordre,
  .mobile_version_bot,
  .mobile_container {
    display: block;
  }

  .mobile_version_bot {
    width: 100%;
  }

  .mc_carousel2 {
    /* padding-top:5%; */
  }

  .non_mobile {
    display: none;
  }

  .mobile_container {
    position: relative;
    top: -20px;
    background: white;
    border-radius: 15px;
  }

  .mc_body {
    padding: 2rem 2rem;
  }

  .mc_restaurant_her_cr {
    font-weight: bold;
  }

  .tab-content {
    width: 100%;
  }

  .tabcontent_custom {
    padding: 1em;
  }

  .menu_item_list {
    width: 100px;
    height: 80px;
    border-radius: 0;
  }

  .menu_item_image {
    margin-left: 0;
  }

  .mc_image_menu_col {
    padding-left: 0;
  }

  .mc_order_button {
    width: 100% !important;
    background: #95C11F 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    text-transform: none;
    padding-right: 0;
    padding-left: 0;
  }

  .detail_tab_content_descr_menu {
    padding-left: 1em;
  }

  .mc_order_button * {
    text-align: center;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }

  .mccol_5 {
    /* padding-left: 1em; */
  }

  .detail_tab_content2 {
    text-align: left;
    color: #3a3a3a;
    font-weight: bold;
    font-size: initial;
  }

  .mc_menu_title {
    font-weight: bold;
    font-size: initial;
  }

  .detail_tab_content_descr {
    padding-top: 1em;
  }

  .dinein_btn_grp4,
  .dinein_btn_grp3,
  .dinein_btn_grp2,
  .dinein_btn_grp1 .order_in_advance {
    font-size: initial !important;
  }

  .icon_dine_in {
    font-size: initial;
  }

  .dinein_takeout {
    margin: auto;
  }

  .dinein_takeout>.card {
    box-shadow: none;
  }

  .carousel-button-group {
    margin-top: -36px;
  }

  .left_btn_tab,
  .right_btn_tab {
    width: auto;
  }

  .carousel-button-group .right_btn_tab {
    float: right;
    margin-top: 0;
    margin-right: 0em;
    padding-right: 0.5em;
  }

  .carousel-button-group .left_btn_tab {
    float: left;
    padding-left: 0.5em;
    margin-left: 0em;
  }

  .react-multi-carousel-item {
    /* width: 200px;
      min-width: 200px;*/
    text-align: center;
  }

  .react-multi-carousel-item a {
    letter-spacing: 0px;
    color: #3A3A3A;
    font-size: 16px;
  }

  .selected_category,
  .Mui-selected {
    color: #FFFFFF !important;
    background-color: #95C11F !important;
  }

  .react-multi-carousel-list.container {
    border-bottom: 1px solid rgb(235, 226, 226);
    background-color: rgba(255, 255, 255, 1);
  }

  .mc_dinordre {
    display: none;
  }

  .mc_carousel2 {
    /* width: 90%;  */
    margin: auto;
  }

  .mc_detail_tab_checkout {
    text-align: center;
    position: fixed;
    bottom: 5%;
    width: 100%;
    margin: auto;
    z-index: 100;
  }

  .mc_detail_tab_checkout button {
    background-color: #9BC22C;
    text-transform: initial;
    border: none;
    color: white;
    /* padding-left: 0;
      padding-right: 0;  */
    /* padding: 1%;  */
  }

  .mc_detail_tab_checkout_card {
    background: white;
    margin: auto;
    z-index: -99;
    width: 50%;
  }

  .modal-dialog {
    width: auto;
  }

  .modal-content {
    width: auto;
  }

  .mc_extraName,
  .mc_extraPrice {
    font-size: small;
  }



  .mc_extraName {
    font-weight: bold;
  }

  .mc_extraPrice {
    font-weight: light;
  }

  .mc_extraBody,
  .mc_extraCounters {
    display: inherit;
  }

  .mc_extraCounters {
    /* float:right; */
    margin: 0;
    padding: 0;
  }

  .mc_extraBody {
    /* background-color: whitesmoke; */
    display: inline-flex;
    align-items: center;
    border-radius: 10px;
    padding: 1.5rem;
  }

  .mc_Checkout {
    /* display: inline-flex;
      align-items: center;   */
    text-align: right;
  }

  .extraCountersButton {
    margin: 0;
    padding: 0;
  }

  .mc_extraInputs {
    margin-left: .2em;
    margin-right: .2em;
  }

  .mc_extraInputsDtl {
    text-align: center;
    /* padding-top: 0 !important;
      padding-bottom: 0 !important; */
  }

  .mc_extraRow {
    margin-bottom: 1em;
  }

  .mc_extraCard {
    box-shadow: none;
  }

  .mc_variationCard {
    background-color: whitesmoke;
  }

  .mc_variant_body {
    background-color: whitesmoke;
    padding: 0;
  }

  .whitesmoke_effect {
    /* margin-bottom: 1.5em; */
  }

  .whitesmoke_effect {
    box-shadow: none;
    /* padding-bottom: 1.5em; */
    background-color: whitesmoke;
    /* border-radius: 10px; */
  }

  .variant_name_intro {
    margin-top: 1em;
  }

  .variationxoption {
    font-size: 14px;
  }

  .mc_detailTabCartVariantItems:last-child {
    margin-bottom: 1.5rem;
  }

  .remove_box_shadow {
    box-shadow: none;
  }

  .mc_extras_variants {
    font-size: medium;
    text-transform: uppercase;
  }

  .mc_cardbody_variants {
    padding: 0 0 !important;
  }

  .mc_variationCard {
    margin-bottom: 1em;
  }

  .detailTabCartVariantItemsBody {
    /* padding-left: 0; */
  }

  .detailTabCartVariantItemsBodyText>div>div>label {
    font-size: 14px !important;
  }

  .detailTabCartVariantItemsBodyText>div>div {
    display: inline-flex;
    align-items: center;
  }

  .attribPrice * {
    display: inline-flex;
    align-items: center;
  }

  .attribPrice,
  .variationx_name,
  .detailTabCartVariantOptions>div label {
    font-size: 14px;
  }

  .add_to_cart_plus_minus {
    font-size: large;
    width: auto;
  }

  .mc_counter_add_cart {
    display: inherit;
    margin-left: -20px;
    width: 36%;
  }

  .mc_menu_col5 {
    margin-left: 20px;
  }

  .mc_detail_tab_content_btn_col>.form-outline {
    height: fit-content;
    max-width: 40px;
    margin-left: 0.2em;
    margin-right: 0.2em;
  }

  .mc_counter_add_cart>.form-outline>input {
    /* padding-top:0;
      padding-bottom:0; */
  }

  .btn-success.add_to_cart {
    font-size: small;
  }

  .mc_menu_list_height_add_cart {}

  .mc_tabcontent_custom_col5 {
    padding-left: 0;
  }

  .add_to_cart {
    height: auto;
    padding: 0.625rem 1.5rem 0.5rem 1.5rem;
    width: 100%;
    border-radius: 10px;
  }

  .mc_detail_tab_checkout_card>.card-body {
    padding: 0;
    padding-top: 1em;
    padding-bottom: 1em;

  }

  .mc_detail_tab_checkout_card {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .mc_checkout_items {
    text-align: left;
    line-height: 1;
  }

  .mc_checkout_items_price {
    font-weight: bold;
  }

  .mc_checkout_items_items {
    font-size: small;
  }

  .modal_confirm_order_subtotal span,
  .modal_confirm_order_subtotal_price span,
  .my_contact,
  .my_table,
  .confirm_order_labels {
    font-size: initial;
  }
 

  .hr_rule_desk_top_hr_rule {
    border-top: 1px solid whitesmoke;
  }

  .hr_rulerow {
    padding-left: 2em;
  }

  .placeorder {
    height: auto;
    width: 88%;
    font-size: small;
  }

  .detailTabCartVariantOptions {
    padding-top: 10px;
  }

  .variationx_name {
    padding-top: 10px;
    margin: 20px 0px 0px 0px;
  }

  .select_pay_method_title {
    font-size: 16px;
  }

  .placeorder2 {
    font-size: 16px;
  }

  .paytitle {
    font-size: 16px;
  }

  .payment_method_select>h5,
  .tipsbody>h5 {
    font-size: initial;
  }

  .processing_complete_title {
    font-size: initial;
  }

  .payment_headerx>h5,
  .complete_processing_amount {
    font-size: 16px;
  }

  .complete_processing_label {
    margin: 0;
    font-size: 16px
  }

  .complete_processing_footer {
    /* padding: 10px; */
  }

  .mc_total_amount_labael div {
    display: inline-block;
  }

  .mc_total_amount_labael:nth-child(2) {
    text-align: right !important;
  }

  .react-multi-carousel-item:not(.react-multi-carousel-item--active) a {
    display: none;
  }

  .mc_menu_list_height {
    margin-left: 0;
  }

  .mc_order_choices {
    width: 100%;
    padding: 1em;

  }

  .mc_order_choices_now {
    font-weight: bold;
    font-size: 16px;
  }

  .mc_order_choices_advance {
    font-size: small;
  }

  .mc_order_choicescol {
    display: inline-block;
    text-align: left;
    /* position: relative; 
      top: -1em; */
  }

  .mc_order_choicescolicon {
    position: relative;
    top: -.2em;
    font-size: x-large
  }

  .mc_order_choices_icon,
  .mc_order_choicescol {
    text-align: right;
  }

  .mc_order_choicescol {
    text-align: left;
  }

  .mc_order_choicescolicon {
    text-align: right;
  }

  .mc_order_choices_btn {
    color: #4f4f4f;
    border: none;
    border-top: 1px solid #4f4f4f;
    border-bottom: 1px solid #4f4f4f;
    padding: 1em 0;
    border-radius: unset;
  }

  .mc_order_choices_btn:hover {
    color: #4f4f4f;
    border: none;
    border-top: 1px solid #4f4f4f;
    border-bottom: 1px solid #4f4f4f;
    padding: 1em 0;
    border-radius: unset;
  }

  .mc_placeorder {
    width: 100%;
    margin: 0;
    padding: 1em !important;

  }

  .order_in_advance div:nth-child(1),
  .order_now_btn div:nth-child(1) {
    text-align: left;
    letter-spacing: 0px;
    color: #3A3A3A;
    opacity: 1;
    font-size: 16px;
    text-transform: none;
  }

  .placeorder {
    border-radius: 10px;
  }

  .mc_button_orderfrequency {
    background: #95C11F 0% 0% no-repeat padding-box !important;
  }

  .mc_button_orderfrequency_row {
    width: 100%;
    margin: auto;
    padding: 1.5em;
  }

  .mc_placeorder_modal_body {
    padding: 0;
    width: 100%;
  }

  .place_order_now {
    padding-top: 0;
  }

  .pickuptime_p,
  .pickupdate_p {
    padding-bottom: 0 !important;
  }

  .mc_order_schedule {
    font-size: initial;
  }

  .mytable {
    margin-left: 0;
    padding-left: 0;
  }

  .css-1s2u09g-control,
  .css-2613qy-menu,
  .css-1pahdxg-control {
    /* width: calc(100% - 3rem); */
    margin: auto;
  }

  .css-2613qy-menu * {
    color: red !important
  }

  #react-select-3-listbox {
    width: calc(100% - 3rem);
    margin-left: 1.5rem !important
  }

  /* .css-1s2u09g-control,.css-b62m3t-container{
      width: calc(100% - 3rem ); 
      margin: auto;
    } */
  .react-tel-input .form-control {
    width: 100%;
  }

  .dinein_takeout {
    width: 100%;
    margin: 1.5rem;
    margin-top: 0;
  }

  .mc_details_col {
    padding: 0;
  }

  .dinein_selected {
    margin-bottom: 0;
  }

  .mytable .validation_error {}

  .mynotes {
    padding-top: 20px;
  }

  .variationxoption2 {
    text-align: right;
  }

  .variantMarginBottom {
    margin-bottom: 20px;
  }

  .variantMarginBottom:last-child {
    margin-bottom: 0;
  }
}

/* special sizes */
@media (max-width: 1023px) {

  .mobile_modals,
  .mobile_modals:hover {
    background-color: none !important;
    border-color: none !important;
    /* color: BLACK; */
  }

  #react-select-2-listbox {
    width: calc(100% - 3rem);
    margin-left: 1.5rem;
  }

  .mobile_version_bot {
    position: absolute;
    left: 0;
  }

  .mobile_container {
    margin-top: 350px;
  }

  .extraNameNew {
    margin-bottom: 0 !important;
  }

  .extraItemRow .extraNameNew,
  .extraItemRow .extraPrice {
    display: inline;
    font-size: 12px;
    line-height: 35px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 700;
  }

  .variation_name {
    font-size: small;
  }


  .extraItemRow {
    padding-left: 1.5rem;
  }

  .hatsune_flex {
    display: flex;
    margin: 0;
    padding: 0;
  }

  .mc_menu_list_height_add_cart {
    flex: 1 1 10em;
  }

  .mc_counter_add_cart {
    width: auto;
    padding: 0px 50px 0px 50px;
  }

  .mc_extraName,
  .mc_extraPrice {
    float: left;
  }

  .mc_extraPrice {
    display: block;
    font-size: 12px;
    font-weight: 700;
    float: right;
    margin: 12px 10px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .attribPrice,
  .variationxoption2price {
    font-size: 12px !important;
  }

  .mc_extraPrice {
    margin-bottom: 0;
  }

  .mc_extraBody {
    display: inline-flex;
    justify-content: space-between;
  }

  .mc_extraCounters {
    width: auto;

  }

  .variationtitle {
    font-size: medium;
    font-weight: initial !important;
  }

  .variationCard3 {
    /* background-color: whitesmoke !important; */
    /* width: calc(100% - 2rem); */
    margin: auto;
    border-radius: 10px;
    padding-top: 1rem;
    padding-bottom: .5rem;
  }

  .variationCard5 {
    background-color: transparent !important;
  }

  .add_cart_image {
    height: auto !important;
  }

  .variationCard6 {
    padding: 0;
  }

  .mobile_css_pad2 {
    padding-left: 0;
  }

  .detailTabCartVariantItemsBodyText .attribLabel .form-check {
    font-size: small;
    /* font-weight: bold; */
  }

  .detailTabCartVariantItemsBodyText .attribLabel .variationxoption .form-check {
    font-weight: initial;
  }

  .detailTabCartVariantItemsBodyText .attribLabel .variationxoption .form-check .form-check-label {
    display: inline;
    padding-top: 3px;
  }

  .detailTabCartVariantItemsBodyText .attribLabel .form-check .form-check-label {
    padding-top: 3px;
  }

  .attribPrice,
  .variationxoption2price {
    font-size: 12px;
    font-weight: 700;
  }

  .variationCard4 {
    padding-left: 0; 
  }

  .variationCard4 .variationxoption2 {
    padding-right: 1.5rem;
  }

  .mc_extraBody {
    padding: 0;
    padding-left: 0;
  }

  .modalcartbody {
    margin: 0px 30px 0px 30px;
    padding: 0px 0px 0px 0px;
  }

  .modalcartbody * {
    --mdb-gutter-x: 0;
    --mdb-gutter-y: 0;
  }
}

/* 
  @media (max-width: 360px) {
    .extraCountersButtonIconNew {
      font-size: small;
    } 
    .extraCountersInputNewCol {
      width: 30px;
    }
  } */

@media (min-width: 1024px) and (max-width: 1500px) {

  .dinein_btn_grp1,
  .dinein_btn_grp2,
  .dinein_btn_grp3,
  .dinein_btn_grp4 {
    padding: 0;
  }
}

@media (min-width: 577px) and (max-width: 1023px) {
  .variationCard3 {
    /* background-color: whitesmoke !important; */
    /*width: calc(100% - 4rem); */
    margin: auto;
    border-radius: 10px;
    padding-top: 1rem;
    padding-bottom: .5rem;
  }

  .mobile_css_pad2 .css_flex .form-check-label {
    font-size: small;
  }

  .form-check-input[type=checkbox] {
    border-radius: 0.125rem;
    margin-top: 8px;
    margin-right: 8px;
  }

  .mc_counter_add_cart .form-outline input {
    text-align: center;
  }

  .detailTabCartVariantItemsX {
    padding-left: 1.5rem;
  }

  .detailTabCartVariantItemsBodyText .attribLabel .form-check {
    /* font-size: initial; */
  }

  .attribPrice,
  .variationxoption2price {
    font-size: initial;
  }
}



@media (max-width: 460px) {
  .extraPriceDisplay {
    display: block;
    width: 100%;
    margin-left: 0;
  }

  .mobile_css_pad2 .attribLabel {}

  .detailTabCartExtraItems {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .remove_cartbody2 {
    padding: 0;
    margin: 0;
  }

  .remove_cartbody2 .modal_card_counter {
    /* float: right; */
  }

  .detailTabCartExtra,
  .detailTabCartVariantItems {
    padding-left: 0;
    /* margin: auto; */
  }

  .detailTabCartVariantItemsX {
    margin: auto;
  }

  .detailTabCartVariantItemsX>div {
    padding-right: 0;
  }

  .remove_cartbody {
    padding-left: 1rem;
  }

  .variation_name {
    font-size: 16px;
    ;
  }

  .modal_card_counter {
    /* float: none; */
  }

  .col.detail_modal_counter.not_add_cart {
    padding: 0;
  }

  .not_add_cart .detail_modal_inc_dec_btn {
    font-size: x-small !important;
  }

  .col.detail_modal_counter.not_add_cart {
    padding: 4px;
  }

  .not_add_cart {
    width: fit-content;
    padding: 1em;
    padding-right: 0;
  }

  .detail_modal_counter .form-outline input {
    font-size: initial;
  }

  .modal_card_counter {
    height: initial;
  }

  .not_add_cart {
    /* margin-left: 3rem; */
  }

  modal_card_counter .remove_cart_row_custom {
    /* margin: auto !important; */
    margin-top: 35px;
  }

  .remove_cartbody2 {
    width: fit-content;
    margin: auto;
    margin-right: 0;
    margin-right: 0;
  }

  .remove_cart_row_custom .modal_card_counter {
    width: fit-content !important;
  }

  .not_add_cart {
    width: fit-content;
  }

  .add_cart_image {
    height: 150px !important;
  }

  .dinein_btn_grp1,
  .dinein_btn_grp2,
  .dinein_btn_grp3,
  .dinein_btn_grp4 {
    padding: 0;
  }

  .order_button {
    font-size: 16px;
  }
}


@media (min-width: 1400px) {
  .restaurant_cover1_col {
    right: 1%;
    padding-left: 0 !important;
  }

  .mc_detail_tab_checkout {
    display: none;
  }

  .restaurant_cover1 {
    height: 380px;
    width: 100%
  }

  .restaurant_cover2 {
    width: 100%;
    height: 180px;
  }

  .mobile_css_spacer {
    width: 0;
    margin: 0;
    padding: 0;
  }

  .restaurant_cover1_col,
  .restaurant_cover2_col {
    width: 33%;
    --mdb-gutter-x: 0 !important;
  }

  .restaurant_cover1_col *,
  .restaurant_cover2_col * {
    --mdb-gutter-x: 0 !important;
  }

  .restaurant_cover2_col {
    margin-left: 0 !important;
    padding-left: 0 !important;
    --mdb-gutter-x: 0 !important;
  }

  .highercatcontainer {
    margin-left: -8px;
  }  
}

@media (max-width: 1400px) {
  .left-btn-cat{
    margin-left: -15px;
  }
}

@media (min-width: 1024px) {
  .hide_desktop {
    display: none;
  }

  .extraItemRow {
    padding: 7px 0px 7px 15px;
  }

  .detailTabCartExtraItems2 {}

  .extraPrice {
    padding-left: 0;
  }

  .extraNameNew {
    display: inline;
    font-size: 12px;
    line-height: 35px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 700;
  }

  .detailTabCartVariantOptions.css_flex {
    padding-left: 0.8rem;
  }

  .detailTabCartVariantOptions.css_flex .variationxoption2price {
    padding-left: 0.8rem;
  }

  footer section>div>div {
    /* z-index: -1; */
  }

  .detailTabCartExtraItemsBodyText .form-outline {
    border: none;
    margin-left: 0.3rem !important;
    margin-right: 0.3rem !important;
  }

  .sidebar_checkout_card_row .extras_breakdown {
    padding-left: 1.5rem;
  }

  .attribPrice {
    font-size: normal !important;
    /* font-weight: bold; */
    padding: inherit;
    display: inline;
    /* width: 50%; */
    text-align: left;
  }

  .variationCard4 .variationxoption {
    /* padding-left: 2.5rem; */
  }

  .attribPriceCol .attribPrice {
    /* padding-right: 1rem; */
  }

  .detailTabCartVariantOptions.css_flex .variationxoption2price {
    padding-right: 10px;
  }

}


@media (min-width: 1366px) and (max-width: 1920px) {

  /* 3 column restaurant page */
  .extraCountersButtonNew i {
    font-size: initial
  }
}

@media (max-width: 439px) {
  .menu_details_all {
    margin: 20px 0;
  }

  .category_intro {
    padding-top: 20px;
  }

  .category_intro .detail_tab_content_descr {
    padding-top: 0;
  }
}

@media (max-width: 460px) {
  .new_modal_cart .modal_card_counter {
    width: fit-content
  }
}

@media (max-width: 577px) {
  .new_modal_cart {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    width: calc(100% - 2rem);
    padding: 0;
    margin-top: 20px;
  }

  .new_modal_cart .new_modal_cart_col {
    padding-left: 0;
  }

  .not_add_cart .detail_modal_inc_dec_btn {
    font-size: small !important;
  }

  .scrollspytabs>div>.hashighercat {
    top: 70px !important;
    padding: 0 !important
  }

  .highercatcontainer {
    padding: 0px 0px 0px 0px;
  }

  .MuiTabs-scroller {
    margin-top: 6px;
  }
}

@media (min-width: 577px) {
  .new_modal_cart {
    margin: 20px 0px 0px 0px;
    padding: 0;
    background-color: #F5F5F5;
    border-radius: 30px;

  }

  .new_modal_cart .new_modal_cart_col {
    padding-left: 0;
  }




}

@media(min-width: 1921px) {
  body {
    margin: auto;
    display: block;
    max-width: 1921px;
    /* display: block;  */
    padding-left: min(140px, 17%);
    padding-right: min(140px, 17%);
  }

  .stickySideBar {
    width: 404px !important;
  }
}

.carousel-button-group~div:nth-of-type(3) {
  padding: 0px !important;
}

.MuiTabs-flexContainer {
  /* justify-content: center; */
}


@media(max-width: 320px) {
  .payment_method_select {
    padding-left: 10px;
    padding-right: 10px;
  }

  .payment_method_select img {
    /* display: none; */
  }

  .payment_method_select label {
    font-size: 10px;
    /* float: right; */
  }
}


@media (min-width: 577px) and (max-width: 991px) {
  .customNavbarNav {
    flex-direction: row;
  }
}

@media screen and (max-width: 1024px) {

  .highercatcontainer {
    background-color: #fff;
  }

  .hashighercat {
    top: 6rem !important;
  }


  .toggle_holder {
    height: 35px;
    padding: 5px 5px 0px 5px;
  }

  .toggle_holder .form-control {
    text-align: center;
    margin: 0px 0px 0px 0px;
  } 
}

@media screen and (max-width: 1399px) {
  .dinein_btn_grp {
    height: 50px;
    margin-top: 0
  }

  .card-body.place_order_now_later {
    padding: 0;
    margin-top: 10px;
  }
}

.highercattitle {
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 16px;
  padding: 0;
  margin: 0;
  white-space: nowrap;
}

.highercattimes {
  display: block;
  width: 100%;
  font-size: 12px;
  line-height: 12px;
  margin: 0;
  white-space: nowrap;
}

.active .highercattitle {
  color: #FFFFFF;
}

.active .highercattimes {
  color: #FFFFFF;
}

.apple_pay_img {
  margin-left: 5px;
}


.highercatcontainer::-webkit-scrollbar {
  width: 16px;
}

.highercatcontainer::-webkit-scrollbar-track {
  background: #F5F5F5;
}

.highercatcontainer::-webkit-scrollbar-thumb {
  background-color: #95c11f;
  border-radius: 10px;
  border: 3px solid #ffffff;
}
 

.pl-0 {
  padding-left: 0;
}

.mobile_add_cart {
  padding: 30px;
}

.mc_counter_add_cart {
  margin: 0 auto
}

/* PREVIEW MODAL */
.previewmodal .variationx_name {
  /* font-size: 14px; */
  margin: 10px 10px;
}

.previewmodal .toggle_holder .form-outline {
  position: relative;
  right: 0
}

.previewmodal .detailTabCartExtraItemsBodyText {
  display: block !important;
  padding: 5px;
  padding-left: 15px;
}

.previewmodal .extraItemRow .extraPrice {
  line-height: 17px
}

.previewmodal .detailTabCartExtraItems .card {
  border-radius: 30px;
  box-shadow: none;
}

.previewmodal .detailTabCartExtraItems {
  padding: 0 !important;
}

.previewmodal .detailTabCartVariantOptions {
  padding: 0px 15px;
}

.mobile-menu-counter {
  display: flex;
  justify-content: center;
  margin-top: -15px;
}

.tip_choices {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
}

._input {
  width: 100% !important;
  border: solid 1px #aaa !important;
  padding: 10px !important;
  outline: none !important;
  background: transparent !important;
  ;
}

._input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.variationCard4 {
  padding-left: 0;
  margin-bottom: 1rem;
}

.no-hover:hover,
.no-hover:active,
.no-hover:focus{
  background-color: transparent !important;
  color: #3A3A3A !important;
}

.itemsCont{
    /* max-height: 50vh; */
    overflow-y: auto;
    /* overflow-x: hidden; */
    padding: 13px;
}

.modal-center-content{
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center; 
}

@media screen and (max-width: 1024px) {
  .previewmodal .extraItemRow {
    padding: 7px 0px 7px 0px
  }

  .previewmodal .detailTabCartExtraItemsBodyText {
    padding: 2px 13px;
  }

  .css_flex {
    padding: 6px 10px 6px 20px;
  }
}

@media screen and (min-width: 1024px) {
  .previewmodal .detailTabCartVariantOptions {
    padding: 10px 15px;
  }


}