.profile .heading {
    padding-top: 3em;
    padding-bottom: 3em;
    margin-bottom: 0;
  }
  
  .profile .avatar {
    width: 120px;
    height: 120px;
    object-fit: cover;
  }
  
  .profile .input_text {
    background: #F3F4F7 0% 0% no-repeat padding-box;
    border-radius: 8px;
    border: none;
    padding-left: 1em;
    height: 40px;
  }
  
  .profile .profile_sections {
    padding-bottom: 1em;
  }
  
  .profile .profile_labels {
    display: flex;
    align-items: end;
  }
  
  .profile .profile_labels p {
    margin-bottom: 0;
  }
  
  .profile .upload_input {
    display: none;
  }
  
  .profile .my_photo {
    color: #C1C1C1;
  }
  
  .profile .profile_name {
    font-size: 20px;
    padding-bottom: 5px;
    margin-bottom: 0;
  }
  
  .profile .profile_labels_row {
    /* height: 20px; */
  }
  
  .profile .profile_labels_row .col-1 {
    height: 20px;
  }
  
  .profile .input_text {
    width: 100%;
  }
  
  .profile .language_btn {
    background-color: #f8f8f8c5;
    border-radius: 15px;
    border: 1px solid #f8f8f8c5;
    color: black;
  }
  
  .profile .language_btn {
    padding-left: 1em;
    display: flex;
    align-items: center;
    width: 50%;
    min-width: 150px;
    background: #F3F4F7 0% 0% no-repeat padding-box;
    border-radius: 8px;
    box-shadow: none;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  
  .profile .language_btn div:first-child {
    text-align: left;
    font-weight: 700;
    font-size: 14px;
    text-transform: none;
  }
  
  .profile .language_btn div:nth-child(2) {
    text-align: right;
  }
  .language-selector {
      float: left;
      font-weight: 700;
      font-size: 16px;
      text-transform: none;
  }
  .profile .submit_btn {
    font-size: 16px;
    text-transform: none;
    background: #95C11F 0% 0% no-repeat padding-box;
    border-radius: 8px;
  }
  
  .profile .submit_btn_class {
    text-align: center;
  }
  
  .profile .input_radio {
    visibility: hidden;
    position: absolute;
  }
  
  .profile .notSelected {
    color: gray !important;
  }
  
  .profile .language_btn {
    margin-bottom: 0;
    padding-bottom: 5px;
    padding-top: 10px;
  }
  
  .profile .language_btn p {
    margin-bottom: 0;
    padding-bottom: 0px;
    padding-top: 0px;
  }
  
  .profile .language_btn, .profile .language_btn p, .profile .language_btn p label {
    width: 100%;
  }
  
  .change_pass_btn{
    margin-left: 1rem;
  }

.profile-label-text {
	color: #96a1b7;
    font-size: 16px;
    font-weight: 700;
}

.profile{
  max-width: 1100px;
}

h4{
  font-weight: bold;
}

.mt-8{
  margin-top: 2rem;
}

.form label{
   font-weight: bold;
}

.form input{
  padding: 10px 20px;
  border-radius: 10px;
  border: solid 2px #AEAEAE;
  margin: 5px 0 15px; 
}

.langselect{
  margin: 5px 0 15px;
  background: transparent !important;
  border: solid 2px #aeaeaf !important;
  height: 51px;
}

.langselect .fas{
  font-size: 20px !important;
} 

.saveBtn{
    position: absolute;
    bottom: 15px;
    width: 96%;
    margin-top: -1px;
    height: 51px;
}

.relative{
  position: relative;
}

.mt-16{
  margin-top: 4rem;
}

.profileplaceholder{
  background-color: #4E4E4E;
  width: 200px;
  height: 200px;
  border-radius: 10px;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; 
}

.changeprofbtndiv{
  display: flex;
  align-items: end;
}

.btnheight{
  height: 51px;
}

@media (max-width: 575px) {
    .profile .heading {
      padding-bottom: 20px;
    }
  
    .profile .avatarcol {
      text-align: center;
    }
  
    .profile .hideInMobile {
      display: none;
    }
  
    .profile .profile_sections {
      font-weight: bolder;
      margin-left: 6%;
    }
  
    .profile .language_row {
      margin-left: 6%;
      margin-bottom: 10px;
    }

    .saveBtn{
        position: relative; 
        margin-top: 35px;
        width: 100%;
    } 
  
   
}

@media (max-width: 991px) {
   .profileplaceholder{
    margin: 0 auto;
   }
} 