.MuiTabs-scroller {
  border-bottom: 1px solid #E2E2E2;
}

.scrollspytabs>div>nav {
  z-index: 999 !important;
}

.left_btn_tab_float {
  float: left;
  height: 69px;
}

.right_btn_tab_float {
  float: right;
  height: 69px;
}

.left_btn_tab,
.right_btn_tab {
  width: auto;
}


@media (min-width: 320px) and (max-width: 576px) {
  .scrollspytabs .carousel-button-group .left_btn_tab {
    width: 20px;
  }


  .category_intro .card-title {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
  }
}

@media (min-width: 1024px) {
  .our_menu {
    /* position: sticky; */
    top: 0;
    background-color: white;
    z-index: 9;
  }

  .scrollspytabs>div>.hashighercat {
    z-index: 9;
    top: 70px !important;
  }

  .scrollspytabs>div>.nohighercat {
    z-index: 9;
    top: 0px !important;
    padding-bottom: 7px;
  }

  .scrollspytabs>div>.ishighercatoverflow{
    top: 90px !important;
  }
}



@media (min-width: 577px) and (max-width: 1399px) {

  .mobile_css_pad1,
  .header_container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin: auto;
  }



  .category_intro .card-title {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
  }

  .tabcontent_custom {
    padding: 0;
  }

  .mobile_css_col1 {
    width: 25%;
  }

  .scrollspytabs>div>nav+.container {
    margin: 0 auto;
    padding: 0;
    max-width: 100%;
  }

  .mobile_css_btn_order {
    text-align: right;
  }

  .mobile_css_btn_order button {
    /* display: block;
    margin: 0;
    padding: 0;
    position: initial; */
  }

  .detail_tab_content_btn_col {
    right: 0;
  }

  .detail_tab_content_btn {
    position: relative;
    right: 0;
  }

  .our_menu_card {
    width: 100%;
  }

  .detail_tab_content_btn {
    max-width: 100px;
  }

  .menu_item_image {
    width: 100%;
    height: auto
  }
}

@media (min-width: 769px) and (max-width: 1023px) {

  .mobile_css_pad1,
  .header_container {
    padding-left: 10%;
    padding-right: 10%;
    margin: auto;
  }
}


@media (max-width: 576px) {
  .mobile_css_pad1 {
    padding-left: 1rem;
    padding-right: 1rem;
    margin: auto;
  }

  .tabcontent_custom {
    padding: 0;
  }

  .mobile_css_col1 {
    width: 25%;
  }

  .scrollspytabs>div>nav+.container {
    margin: 0 auto;
    padding: 0;
    max-width: 100%;
  }

  .mobile_css_btn_order {
    text-align: right;
    width: 25%;
  }

  .mobile_css_col2 {
    width: 50%;
  }

  .mobile_css_btn_order button {
    /* display: block;
    margin: 0;
    padding: 0;
    position: initial; */
    text-transform: none;
  }

  .detail_tab_content_btn_col {
    right: 0;
  }

  .detail_tab_content_btn {
    position: relative;
    right: 0;
  }

  .our_menu_card {
    width: 100%;
  }

  .detail_tab_content_btn {
    max-width: 150px;
  }

  .menu_item_image {
    height: 80px;
    width: 100%;
  }

  .detail_tab_menu_item_name {
    text-align: left;
    font-weight: bold;
  }

  .mobile_css_none {
    display: none
  }

  .detail_tab_content_btn {
    padding: 0;
  }
}


@media (max-width: 439px) {

  .mobile_css_col1,
  .mobile_css_col2 {
    width: 100%;
    text-align: left;
  }

  .detail_tab_content_btn {
    width: 100% !important;
    max-width: 100%;
  }

  .menu_item_image {
    height: 200px;
    width: 100%;
  }

  .mc_image_menu_col .menu_item_image {
    height: 200px;
  }
}

@media( min-width: 577px) and (max-width: 1399px) {
  .mobile_css_col2 {
    width: 50%; 
  }

  .mobile_css_btn_order{
    width: auto;
  }
}

