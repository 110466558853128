 ._container{
    max-width: 600px;
    background-color: #fff; 
    border-radius: 20px;
    margin: 1rem auto;
    padding: 0;
    color: #000;
    padding-bottom: 1rem;
 }

 h5,h3{
    font-weight: bold;
 }

 .head{
    padding: 2rem 
 }

 .subhead{ 
    padding: 2rem  ;
    background-color: #000;
    font-weight: bold;
 }

 .subhead p{ 
   padding: 2rem  2rem 0;
   color: #fff; 
}

 .userinfo{
    padding: 2rem 
 }

 .category{
    padding: 2rem 
 }

 .categories .odd{
    background-color:#F5F5F5 ;
 }
 .categories .even{
    background-color:#fff ;
 }

 .comments{
    padding: 2rem;
    background-color:#F5F5F5 ; 
 }

 .comments textarea{
    padding: 10px;
    border: none;
    width: 100% ;
    outline: none;
 }

 .comments textarea:active{
    outline: none;
 }

 .sendbtn{
    background: rgb(232, 198, 80);
    border: navajowhite;
    padding: 10px 45px;
    color: #000;
    font-weight: bold;
    border-radius: 10px;
 }

 .footer{
    padding: 2rem;
    background-color: #fff;
    padding-bottom: 0;
 }

 .footer img{
   width: 150px;
 }

 .startcont{
    display: flex;
 }

 .startcont > div {
    width: 20%;
    position: relative;
 }

 .startcont .fa-star {
    font-size: 35px;
 }
 
 .startcont .ratenum{
    position: absolute;
    left: 47%;
    margin-top: 11px;
    font-size: 11px;
    font-weight: bold;
 }

 .star_2{
   left: 45% !important;
 }
 
 .star_3{
   left: 45% !important;
 }

 .star_4{
   left: 45% !important;
 }

 .star_5{
   left: 45% !important;
 }

 .startcont{
    max-width: 400px;
    margin: 0 auto;
 }
 .startcont .starbox{
    background:#CECECE;
    padding: 10px; 
    margin: 0 auto;
    border-radius: 20px;
    display: inline-block;
    cursor: pointer;
 }

 .startcont .starbox-selected{
   background:rgb(232, 198, 80); 
}


 .ratetxt{
   margin-top: 1rem;
   font-size: 12px;
   font-weight: bold;
 }

 .container-success{
   margin-top: 10rem;
 }

 .successdiv{
   height: 300px;
   padding: 1rem;
   text-align: center; 
   font-size: 20px;
   font-weight: bold;
   padding-top: 8rem;
 }

 @media screen and (max-width:600px) {
    .container{ 
        max-width: 95% !important;
    } 
 }


 @media screen and (max-width:450px) {
   .startcont .fa-star{
      font-size: 26px
   }

   .ratenum{
      margin-top: 6px !important;
      font-size: 10px  !important;
   }
}