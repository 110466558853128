.orders_restaurant_header_container, .orders {
    max-width: 1024px;
    padding: 0 50px;
}

.orders .heading {
    padding-top: 1em;
    padding-bottom: 1em;
    margin-bottom: 0;
}

@media (min-width: 768px) {
    .orders .heading {
        padding-top: 3em;
        padding-bottom: 3em;
        margin-bottom: 0;
    }
}

@media (min-width: 1024px) {
    .orders_restaurant_header_container {
        max-width: 1920px !important;
        padding: 0 130px !important;
    }

    .orders {

        max-width: 1920px;
        padding: 0 130px;
    }
}

/* @media (min-width: 1024px) and (max-width: 1365px) {
    .restaurant_page_navbar>.container-fluid {
        max-width: 1920px !important;
    }
} */



.orders .restaurant_cover {
    width: 100%;
    height: 140px;
    object-fit: cover;
    border-radius: 10px;
}

.orders .card-body {
    padding: 1.5rem 0;
}

.orders .details, .orders .breakdown {
    background: #F3F4F7 0% 0% no-repeat padding-box;
    border-radius: 13px;
    margin-top: 1.5rem
}

.orders .details .card-body, .orders .breakdown .card-body {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.orders .details .card-body p, .orders .breakdown .card-body p {
    font-size: 10px;
    margin: 0;
}

.orders .breakdown .card-body .row {
    margin-bottom: 5px;
}

.orders .cardborder {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #6f6f6f;
    padding: 1em;
    margin-bottom: 1em;
}

.orders .orderlist {
    padding-top: 0;
}



.orders .avatar {
    width: 120px;
    height: 120px;
    object-fit: cover;
}

.orders .input_text {
    background: #F3F4F7 0% 0% no-repeat padding-box;
    border-radius: 8px;
    border: none;
    padding-left: 1em;
}

.orders .profile_sections {
    padding-bottom: 1em;
}

.orders .profile_labels {
    display: flex;
    align-items: end;
}

.orders .profile_labels p {
    margin-bottom: 0;
}

.orders .upload_input {
    display: none;
}

.orders .my_photo {
    color: #C1C1C1;
}

.orders .profile_name {
    font-size: 20px;
    padding-bottom: 5px;
    margin-bottom: 0;
}

.orders .profile_labels_row {
    /* height: 20px; */
}

.orders .profile_labels_row .col-1 {
    height: 20px;
}

.orders .input_text {
    width: 100%;
}

.orders .language_btn {
    background-color: #f8f8f8c5;
    border-radius: 15px;
    border: 1px solid #f8f8f8c5;
    color: black;
}

.orders .language_btn {
    padding-left: 1em;
    padding-bottom: 0;
    padding-top: 0;
    display: flex;
    align-items: center;
    width: 50%;
    min-width: 150px;
    background: #F3F4F7 0% 0% no-repeat padding-box;
    border-radius: 8px;
    box-shadow: none;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
}

.orders .language_btn div:first-child {
    text-align: left;
}

.orders .language_btn div:nth-child(2) {
    text-align: right;
}

.orders .submit_btn {
    text-transform: none;
    background: #95C11F 0% 0% no-repeat padding-box;
    border-radius: 8px;
}

.orders .submit_btn_class {
    text-align: center;
}

.orders .orderlist .details .card-body {
    display: inline-flex;
}

.orders .orderlist .details .cola {
    /* display: inline-block; */
}

.orders .orderlist .details .colb {
    display: inline-flex;
    justify-content: flex-end;
}

.orders .orderlist .details .colb div {
    align-items: center;
}

.orders .goRight {
    text-align: right;
}

.orders .goGreen {
    color: #95C11F;
}

.orders .breakdown {
    font-size: 10px;
}

.orders .reorder, .orders .reorder:hover, .orders .reorder:active, .orders .reorder:visited {
    width: 100%;
    border-radius: 15px;
    margin-top: 20px;
    background: #95C11F 0% 0% no-repeat padding-box;
}