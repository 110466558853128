body {
  font-family: 'DM Sans';
}

.restaurants_landing {
  border-radius: 2em;
  /* max-width: 100%; */
}

.restaurant_link {
  color: #3a3a3a;
  font-weight: 700 !important;
}

.footer_left a {
  color: black;
}

.restaurants_col .card-body {
  text-align: left;
}

.navbar {
  height: 85px !important;
}

.starBuff,
.starBuff:hover,
.starBuff:active,
.starBuff:visited {
  display: inline-block;
  /* top: 41px;
  right: -255px; */
  background: none;
  border: none;
  /* color:#9BC22C; */
  box-shadow: none;
  background: #95C11F 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  height: 34px;
  text-align: center;
  font: normal normal normal 16px DM Sans;
  letter-spacing: 0px;
  color: #FFFFFF;
  padding-bottom: 2em;
  text-transform: initial;
}

.closeStop,
.closeStop:hover,
.closeStop:active {
  display: inline-block;
  /* top: 41px;
  right: -248px; */
  background-color: rgba(255, 0, 0, 0);
  border-radius: 10px;
  opacity: 1;
  color: red;
  box-shadow: none;
  text-transform: none;
  font-weight: bold;
}

.starBuff:hover,
.starBuff:hover,
.starBuff:active,
.starBuff:visited {
  /* background-color: #88B11A !important; */
  /* background-color: rgba(255, 0, 0, 0); */
  /* color: #95C11F; */
  box-shadow: none;
}

.restaurant_close_btn {
  box-shadow: none;
}

.restaurant_detail_modal img {
  width: 50%;
  padding: 0;
  margin: 0;
  display: inline;
  padding: 1em;
}

.restaurant_detail_modal .card-body {
  display: none;
}

/*
  // X-Small devices (portrait phones, less than 576px)
  // No media query for `xs` since this is the default in Bootstrap
  
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) { ... }
  
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) { ... }
  
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { ... }
  
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { ... }
  
  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { ... }
  */
/* @media (min-width: 1400px) { ... } */

/*
  // X-Small devices (portrait phones, less than 576px)
  // No media query for `xs` since this is the default in Bootstrap
  */
.restaurant_navbar {
  /* background-color: transparent !important; */
  background-color: #9BC22C;
  background-image: none !important;
  height: auto !important;
  min-height: 12vh;
  padding: 0em !important;
  max-width: 100% !important;
  /* position: absolute; */

  box-shadow: none;
}

.navbar {
  box-shadow: none;
}


.restaurants_col div {
  text-align: left;
}

.navbar-toggler {
  display: none;
}

.restaurant_detail_modal .card {
  display: inline;
}

.restaurant_detail_modal .img {
  width: 50%;
  display: inline;
  margin: 0;
  padding: 0;
}

.restaurants_col img {
   width: 100%;
}

@media (min-width: 1024px) {
  .details_row {
    padding-left: min(140px, 17%);
    padding-right: min(140px, 17%);
  }

  .restaurant_navbar {
    background-color: #9BC22C;
    background-image: none !important;
    height: auto !important;
    min-height: 12vh;
    padding: 0em !important;
    max-width: 100% !important;
    box-shadow: none;
  }


  .restaurants_row {
    /* padding-top: 8em !important; */
    padding-left: 20em;
    padding-right: 20em;
  }

  .restaurants_col {
    padding-top: 1.5rem;
    /*margin-bottom: 40px;*/
    min-width: 365px !important;
    max-width: 365px !important;
    text-align: center;
  }

  .restaurants_col img {
    object-fit: cover;
    width: 22em;
    height: 175px;
    /* min-width: 22em !important; */
    min-height: 175px !important;
    border-radius: 20px 20px 0px 0px;
  }

  .restaurants_col card-body {
    text-align: left;
  }

  .restaurants_row .restaurants_col {
    /* min-width: 23em !important; */
    text-align: left;
  }

  .restaurants_col>div>.card-body {
    text-align: left !important;
    font-size: 14px;
    font-weight: 500;
    color: #3a3a3a;
    padding: 40px;
    border-radius: 0px 0px 20px 20px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  }

  .restaurants_col>div>.card-body .card-title {
    color: #000;
    font-family: Ubuntu;
    font-size: 20px;
    font-weight: 500;
  }

  .starBuff,
  .starBuff:hover,
  .starBuff:active,
  .starBuff:visited {
    /* background-color:green;  */
    color: white;
    border-color: none !important;
    background: #95C11F 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    font-size: 12px;
    font-weight: 700;
  }

  .closeStop,
  .closeStop:hover {
    /* background:red; */
    border-color: none !important;
    background: #C11F1F 0% 0% no-repeat padding-box !important;
    border-radius: 20px;
    opacity: 1;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #FFFFFF !important;
    box-shadow: none;
    text-transform: none;
  }

  .closeStop:hover,
  .closeStop:active {
    /* background:red; */
    background: #C11F1F 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    color: red;
    box-shadow: none;
    text-transform: none;
  }
}

.navigation_bar_small {
  display: none;
}

.card-title h5 {
  font-size: 20px;
  color: #3a3a3a;
  font-weight: 700;
}

@media (max-width: 576px) {
  .restaurants_row .restaurants_col>div {
    /* margin: auto; */
  }

  .restaurants_row .restaurants_col>div.card {
    box-shadow: none;
    padding: 1.5rem 0;
  }

  .restaurants_landing {
    border-radius: 10px;
  }

  .restaurants_col .card-body {
    padding: 0;
  }


  .navigation_bar_small {
    display: flex;
    align-items: center;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    margin: auto;
    z-index: 100;
    background-color: white;
    height: 50px;
  }

  .navigation_bar_small a {
    color: #95c11f;
    font-size: 1.2rem;
  }

  .paragraph4_container {
    display: none;
  }

  footer {
    text-align: center !important;
    margin-bottom: 50px;
  }

  .starBuff,
  .starBuff:hover,
  .starBuff:active,
  .starBuff:visited {
    display: inline-block;
    top: 50px;
    right: -122px;
    background: none;
    border: none;
    /* color:#9BC22C; */
    box-shadow: none;
    background: #95C11F 0% 0% no-repeat padding-box;
    border-radius: 25px;
    opacity: 1;
    height: 34px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
  }

  .closeStop {
    top: 50px;
    right: -122px;
    background: #C11F1F 0% 0% no-repeat padding-box !important;
    color: white;
    box-shadow: none;
    border-radius: 25px;
    font-size: 12px;
    font-weight: 700;
  }

}

.starBuff{    
  position: absolute;
  right: 10px;
  top: 10px;
}

@media (min-width: 320px) and (max-width: 1023px) {
  .restaurants_col .restaurants_landing {
    padding: 0%
  }

  .restaurants_col {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (max-width: 576px) {
  .restaurant_header_container {
    display: block !important;
    width: 100%;
    padding-right: 3rem;
    padding-left: 3rem;
    margin: auto;
  }

  .restaurant_header_login {
    text-align: right;
    display: inline-block;
    width: 50%;
    font-size: small;
  }

  .restaurant_header_profile {
    text-align: right;
    display: inline-block;
    width: 50%;
  }

  .custom_navbar_links_text {
    font-size: small !important;
    padding-right: 0 !important
  }

  .restaurant_header_col2 {
    padding: 0.5em;
    text-align: right;
  }

  .restaurant_header_profile {
    display: none;
  }

  .restaurant_header_imagecol {
    padding-left: 0;
  }

  .restaurants_landing {
    max-width: 504px !important;
    width: 100%;
  }

  .restaurants_col {
    margin-top: 1.5rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}


@media (min-width: 577px) and (max-width: 991px) {
  .restaurant_header_container {
    display: block !important;
    max-width: 504px;
    width: 100%;
    /* padding-right: 3rem;
      padding-left: 3rem; */
    margin: auto;
  }

  .restaurants_col .card-body {
    padding-left: 0;
  }

  .restaurant_header_imagecol,
  .restaurant_header_col2 {
    display: inline-block;
    width: 50%;
  }

  .restaurants_row { 
    justify-content: center;
    display: flex;
    padding-left: 0;
    padding-right: 0;
    gap: 50px;
  }

  .restaurants_col {
    padding: 0;
    margin: auto;
    max-width: 504px;
    margin-top: 1.5rem;
  }

  .restaurant_header_login {
    text-align: right;
    display: inline-block;
    /* width: 50%; */
    font-size: small;
  }

  .restaurant_header_profile {
    text-align: right;
    display: inline-block;
    width: 50%;
  }

  .custom_navbar_links_text {
    font-size: small !important;
    padding-right: 0 !important
  }

  .restaurant_header_col2 {
    padding: 0.5em;
    text-align: right;
  }

  .restaurant_header_profile {
    display: none;
  }

  .restaurant_header_imagecol {
    padding-left: 0;
  }

  .restaurants_landing,
  .restaurant_header_imagerow {
    max-width: 504px !important;
  }

  .restaurants_landing {
    border-radius: 10px;
  }

  .restaurant_header_imagerow {
    /* margin-left: calc(100% - 3rem); */
  }

}

@media (min-width: 992px) and (max-width: 1023px) {
  .restaurant_header_container {
    display: block !important;
    max-width: 504px;
    width: 100%;
    /* padding-right: 3rem;
    padding-left: 3rem; */
    margin: auto;
  }

  .restaurants_col .card-body {
    padding-left: 0;
  }

  .restaurant_header_imagecol,
  .restaurant_header_col2 {
    display: inline-block;
    width: 50%;
  }

  .restaurants_row { 
    justify-content: center;
    display: flex;
    padding-left: 0;
    padding-right: 0;
    gap: 50px;
  }

  .restaurants_col {
    padding: 0;
    margin: auto;
    max-width: 504px;
    margin-top: 1.5rem;
  }

  .restaurant_header_login {
    text-align: right;
    display: inline-block;
    /* width: 50%; */
    font-size: small;
  }

  .restaurant_header_profile {
    text-align: right;
    display: inline-block;
    width: 50%;
  }

  .custom_navbar_links_text {
    font-size: small !important;
    padding-right: 0 !important
  }

  .restaurant_header_col2 {
    padding: 0;
    text-align: right;
  }

  .restaurant_header_profile {
    display: none;
  }

  .restaurant_header_imagecol {
    padding-left: 0;
  }

  .restaurants_landing,
  .restaurant_header_imagerow {
    max-width: 504px !important;
  }

  .restaurants_landing {
    border-radius: 10px;
  }

  .restaurant_header_imagerow {
    /* margin-left: calc(100% - 3rem); */
  }
}

@media (min-width: 1024px) and (max-width: 1365px) {
  .restaurants_row {
    justify-content: center;
    display: flex;
    padding-left: 0;
    padding-right: 0;
    gap: 50px; 
  }

  .restaurants_col .card-body {
    padding-left: 0;
  }

  .restaurants_col {
    min-width: 500px !important;
  }

  .restaurants_landing {
    min-width: 100% !important;
  }

  .restaurant_header_container {
    display: block !important;
  }

  .restaurant_page_navbar>.container-fluid {
    max-width: 1000px !important;
  }

  .restaurant_header_imagerow {
    display: flex;
    justify-content: center;
  }

}

@media (min-width: 1366px) {
  .restaurants_row {
    justify-content: center;
    display: flex;
    padding-left: 0;
    padding-right: 0;
  }

  .restaurant_page_navbar>.container-fluid {
    max-width: 1200px !important;
  }



  .restaurant_page_restaurants_row {
    justify-content: center;
    display: flex;
    gap: 50px;
    padding-left: 0;
    padding-right: 0;
    max-width: 1200px !important;
    margin: auto;
  }

  .restaurants_col .card-body {
    padding-left: 0;
  }

  .restaurants_col {
    min-width: 350px !important;
  }

  .restaurants_landing {
    min-width: 100% !important;
  }

  .restaurant_header_container {
    display: block !important;
  }

  .restaurant_header_imagerow {
    display: flex;
    justify-content: center;
  }


}



@media (min-width: 1024px) {
  .restaurant_header_col2 {
    width: 30%;
    text-align: right;
  }

  .restaurant_header_imagerow {
    display: flex;
    justify-content: space-around;
    ;
  }

}

@media (min-width: 577px) {

  .restaurant_header_profile,
  .restaurant_header_login {
    display: inline-block;
    /* width: 50%; */
  }

}


@media (min-width: 1024px) and (max-width: 1365px) {
  .paragraph4_container {
    max-width: 1000px !important;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .paragraph4_container {
    display: block !important;
    max-width: 504px;
    width: 100%;
    margin: auto;
  }
}

@media (min-width: 577px) and (max-width: 991px) {
  .paragraph4_container {
    display: block !important;
    max-width: 504px;
    width: 100%;
    margin: auto;
  }
}

.custom_navbar_links_text:hover,
.custom_navbar_links_text:active,
.custom_navbar_links_text:visited {
  /* background: red !important */
}

.restaurant_page_restaurants_row {
  /* padding-left: 20px;
    padding-right: 20px */
}

.download_here {
  text-align: center;
  display: inline-flex;
  justify-content: space-between;
}

.download_here {
  /* width: 200px; */
  size: small;
}

.download_here .apple,
.download_here .apple {
  font-size: small
}


.Toastify__toast-body {
  /* background-color: #FCEDE9; */
  background-color: #fff;
}

/* .Toastify__toast Toastify__toast-theme--light Toastify__toast--default{
    margin
  } */
.restaurant_header_login,
.restaurant_header_profile {
  cursor: pointer !important;
}


.search-bar {
  margin-bottom: 0;
  position: relative;
}

.search-bar input {
  width: 100%;
  outline: none;
  padding: 20px 30px 20px 30px;
  border: none;
  border-radius: 30px;
  height: 59px;
  color: #505050;
  margin-top: 2rem;
  background-color: #F5F5F5;
  font-size: 15px;
}

.search-bar .addon {
  background: #95c11f;
  border-top: solid 1px #95c11f;
  padding: 16px 35px 15px 35px;
  color: #fff;
  position: absolute;
  top: 32px;
  right: 0;
  border-radius: 30px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
}

.search-bar .addon i {
  margin-left: 10px;
  ;
}

@media screen and (max-width: 1366px) {
  .search-div {
    padding: 0 3rem;
  }

  .search-bar .addon {}
}