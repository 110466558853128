.my-facebook-button-class {
  border-radius: 10rem;
  background-color: #fbfbfb;
  vertical-align: bottom;
  border: 0;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 10%);
  font-weight: 500;
  padding: 0.625rem 1.5rem 0.5rem 1.5rem;
  font-size: .75rem;
  line-height: 1.5;
  width: 100%;
  margin-bottom: 2em;
}

.signup_link {
  color: #94c11e !important;
}

.detail_signin_submit {
  background-color: #94c11e !important;
  font-size: 15.6px;
  width: 100%;
  border-radius: 25px;
  text-transform: none;
}

.sign_in_others {
  width: 100%;
  margin-bottom: 1em;
}

.detail_signin_input_form input {
  margin-bottom: 1em;
}

#agree_to_terms {
  float: left;
  /* display:inline-block; */
}

.detail_signin_input_form label {
  /* display:inline-block; */
  /* width: */
  float: left;
  font-size: small;
  vertical-align: middle;
}

@media (min-width: 769px) {
  .signin_container {
    max-width: 100%;
  }
}