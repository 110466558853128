body {
    font-family: 'DM Sans';
  }
  
  .navbar-nav { 
    display: flex;
    flex-direction: inherit; 
  }
  
  @media (min-width: 576px) {
    .landing_navbar {
      /* background-color: transparent !important; */
      background-color: #9BC22C;
      background: url(../../../public/frontpage-girl.png);
      height: 80vh;
      min-height: 80vh;
      padding: 0em !important;
      max-width: 100% !important;
      /* position: absolute; */
      right: 0;
      left: 0;
      box-shadow: none;
    }
  
    .navbar>.container-fluid {
      /* position:absolute;
      top:0;
      padding:1em 8em 1em 8em; */
    }
  
    .navbar-nav {
      text-align: left;
  
    }
  
    .nav-link {
      /* color: rgba(255,255,255,1) !important; */
    }
  
    .custom_navbar_links_text {
      letter-spacing: 0px;
      color: #3A3A3A !important;
    }
  
    .me-2 {
      border-radius: 20px;
      min-width: max-content;
      text-transform: revert;
      font-size: medium;
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
  .me-2:hover {
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
  .me-2:focus {
      background-color: #95C11F;
      border: none;
      box-shadow: none;
    }
    .nav-link {
      font-size: initial;
    }
  
    .navbar-nav {
      padding-left: 8em;
    }
  
    .card {
      box-shadow: none;
    }
  
    .icon_landing {
      max-height: 120px;
    }
  
    .card_container {
      /* padding: 4em; */
    }
  
    .food {
      display: inline-block;
      width: 40%;
      padding: 8em;
      color: white;
    }
  
    .food>h1 {
      font-size: 4.5em;
      font-weight: 400;
      font-family: 'DM Sans';
    }
  
    .delicious {
      padding-top: 2em;
      padding-bottom: 2em;
      opacity: 0.75;
    }
  
    .phone {
      width: 80%;
      float: right;
      top: 3em;
      margin-top: 55%;
    }
  
    .landing_phone {
      position: absolute;
      right: 8em;
      top: 3em;
      width: 60% !important;
  
    }
  
    .paragraph4 {
      padding-right: 4em;
    }
  
    .paragraph4 .ripple {
      margin-top: 4em;
    }
  
    .paragraph1_container, .paragraph2_container {
      margin-left: 10%;
      margin-top: 4em;
    }
  
    .paragraph4_container {
      max-width: 1200px !important;
      margin: auto;
    }
  
    .paragraph1 .lead, .paragraph2 .lead, .paragraph4 .lead {
      opacity: 0.5;
      margin-top: 2em;
      margin-bottom: 4em;
    }
  
    .paragraph3_container {
      padding-top: 4em;
    }
  
    .paragraph3_container .col {
      text-align: center !important;
    }
  
    .paragraph3_container .col .card {
      margin: auto;
    }
  
    .paragraph3_demo {
      padding-top: 4em;
    }
  }